import React from 'react';
import { Component,Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Resizer from 'react-image-file-resizer';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import qrcodeIcon from '@iconify/icons-mdi/qrcode';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';

import ImageUploader from 'react-images-upload';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { server,cookies,domain_name,local,store_type,getMOS } from '../environ';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import {translator} from  '../parrot-js/parrot';
import InputBase from '@material-ui/core/InputBase';

import Tooltip from '@material-ui/core/Tooltip';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import StockCellW from './StockCell'

import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';

const useStyles = theme => ({
  root: {
    width: '100%',
  },
   lroot: {
    width: '100%',
    margin : theme.spacing(0, 0, 4, 0)
  },
   formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
   title: {
    flexGrow: 1,
  },
  noitems: {
    minHeight: '80vh',
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'column',
    margin : theme.spacing(0, 4)
  }
});

const object_name = "Portfolio";
const object_table = "tb_portfolio";

const listD = { pid : "stock_id", sid : "" }

function Searchbar (props) {
    return(
    <div style={{ paddingLeft : "16px", paddingRight : "16px"}}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={props.data.map(o => o)}
        getOptionLabel={o =>  o.symbol + ' (' + o.name + ')' }
        onChange = {props.handleOnChange}
        clearOnEscape
        onInputChange = {props.handleOnInputChange}
        renderInput={params => (
        <TextField {...params}   style={{ marginTop : "16px", marginBottom : "2px" }}  
          onChange={({ target }) => { props.handleType(target.value); } } 
          label="Add Stock" margin="normal" variant="outlined" fullWidth />
        )}
        renderOption={(o, { selected }) => (
            <React.Fragment>
            <Grid container direction="column" justify="space-between" >
            <Grid container justify="space-between" >
            <Typography variant="subtitle1" children={o.symbol} />
            <Typography variant="caption" children={o.exchDisp} />
            </Grid>
            <Grid container justify="space-between" >
            <Typography variant="body2" children={o.name} />
            </Grid>
            </Grid>
            </React.Fragment>
          )}
    />
    </div>
    );
  }

function exAb(x) {
  if (x == "NASDAQ") return "NSDQ+"
  if (x == "NSE") return "NSE+"
  if (x == "CCC") return "CRY"
  return x;
}

function readIDB(key,db,callback) {
  console.log("read idb " + key, db )
  if (db == null) return callback("db not ready",null)
  var r = db.transaction(["xyz"]).objectStore("xyz").get(key);
  r.onerror = function(event) { console.log("Unable to retrieve data from database! " + key);
    return callback(event,null)
  };
  r.onsuccess = function(event) { // console.log("success" + r.result)
    return (r.result == undefined) ? callback("error",null) : callback(null,r.result.data); 
  };
}

function addIDB(key,data,db) {
  if (db != null){
    var r = db.transaction("xyz", "readwrite").objectStore("xyz").add({key :key,data : data});   
    r.onsuccess = function(event) { };
    r.onerror = function(event) { console.log("unable to add " + key,event); }
  }
}


class X extends Component {

  constructor(props){
      super(props)
      this.state = {
        origlist : [],
        list : [],
        open : false,
        modify_item_data : {},
        modify_item : false,
        iconTag : null,
        data : [],
        textbox : "",
        addItem : false,
        period : "Y",
        exchanges : [],
        pickedEx : null,
        linI : false,
        tags : [],
        pickedTag : null
      }
  }

  componentDidMount = () => {
    this.fetchList('linear',null);
  }

  componentDidUpdate = (prevProps) => {
  }

  preFetch = (l) => {

    l.forEach(x => {
   //   this.fetchList(x.stock_id)
    //  console.log(l)
    })
  }

  fetchList = (x) => { 

    let url = server + '/public/yahoofinance/historical?' + 'symbol=' + x + '&from=' + '2016-05-01' + '&to=' + '2122-01-01'

    var data = null;
    var key = 'h/' + x + '/' + (new Date()).toISOString().split('T')[0];
    console.log("h1")
    readIDB(key,this.props.db,(err,cached) => {
      console.log(err,cached)
      if (cached != undefined && cached != null && err == null) { 
      }
      else
      { 
        fetch(url)
        .then(response => response.json())
        .then(jsondata => { 
        if(jsondata.status == "success") { //console.log("rendering and add " + key)
          data = jsondata.data; 
          try  { addIDB(key,data,this.props.db) }
          catch (e) { console.log(e,x); }
        }
        })
        .catch(err =>  { console.log(err)} )
      }
    })
     console.log("h2")

  }

  getRefinedList = (list,pe,t) => { //console.log(list,pe,t)
    return list
      .filter( x => { return (x.is_active == 1) })
      .filter(x => { if (pe == null) return true; else return (x.exchange == pe)  })
      .filter(x => { if (t == null) return true; else if (x.tags != null && JSON.parse(x.tags).length != 0) return x.tags.includes(t); else if (t == "others" && ( x.tags == null || JSON.parse(x.tags).length == 0) ) return true; else  return false; })
  } 

  setExchanges = (l) => {
        var exchanges = [];

        l.forEach(x => { if (!exchanges.includes(x.exchange)) exchanges.push(x.exchange); })
        this.setState({exchanges : exchanges});
        if (this.state.pickedEx == null && exchanges.length > 0) this.setState({ pickedEx : exchanges[0] }) 
  }

  setTags = (l,pe,from) => { 
     var t = []
      l.filter(x => { if (pe == null) return true; else return (x.exchange == pe)  })
      .forEach(x => { if (x.tags != null) this.processTags(x.tags,t); })
      t.push('others')
      t = t.filter(x => { return x != null})
      this.setState({ tags : t })
      if (this.state.pickedTag == null || from == "setexchange") 
        { this.setState({ pickedTag : t[0] }); return t[0]; }
      else if (this.state.pickedTag != null && from == "fetchlist")
        { return this.state.pickedTag}
      else
        { this.setState({ pickedTag : "others"}); return "others"; }
  }

  fetchList = (ind,scrollToBottom) => {
    if (ind == 'circular') this.setState({cirI : true}); else this.setState({linI : !(ind == false) })
      
    let url = server + '/user/object/listall?access_token=' + cookies.get('/user/access_token') + '&lc=' + this.props.lc;
        url = url + '&object=' + this.props.object_table
    fetch(url)
    .then(response => response.json())
    .then(jsondata => { 
    if(jsondata.status == "success") { 
        jsondata.data.forEach(x => { if (x.exchange=="Industry") x.exchange = "NSE"; if (x.exchange=="NYSE") x.exchange = "NASDAQ"  })
        var l = jsondata.data.filter( x => { return (x.is_active == 1) })
        this.setState({origlist : l})
        this.preFetch(l);
        if (scrollToBottom == true) this.scrollToBottom();
        this.setExchanges(l);
        var pickedTag = this.setTags(l,this.state.pickedEx,"fetchlist");
        var rl = this.getRefinedList(jsondata.data,this.state.pickedEx,pickedTag) 
        this.setState({ list : rl, linI : false, cirI : false});
    }
    else
    {
      Object.entries(cookies.getAll()).map(obj => cookies.remove(obj[0]));
    }
    })
    .catch(err =>  {this.setState({ linI : false, cirI : false})} )
  }

  processTags (tags,t) { 
    JSON.parse(tags).forEach(x => { if (!t.includes(x)) t.push(x);  });
  }

  createEditObject = (opr,object_id,object_data,scrollToBottom) => { 

    var data = { object_data : object_data, object_type : this.props.object_table, object_id : object_id, opr : opr};
    data.access_token = cookies.get('/user/access_token');
    data.lc           = this.props.lc;
    
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };
    let url = server + '/user/object/crud'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success") { 
        this.fetchList(false,scrollToBottom); 
      }
      else this.props.displaySM("Some Error Occured") 
    });
  }

  createEditObjectTodo = (opr,object_id,object_data,scrollToBottom) => { 

    var data = { object_data : object_data, object_type : 'tb_todos', object_id : object_id, opr : opr};
    data.access_token = cookies.get('/user/access_token');
    data.lc           = this.props.lc;
    
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };
    let url = server + '/user/object/crud'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success") { 
        this.fetchList(false,scrollToBottom); 
      }
      else this.props.displaySM("Some Error Occured") 
    });
  }

  handleOnChange = (event,value,reason) => {
    if (reason != "clear")
    { var obj = {stock_id : value.symbol,exchange : value.exchDisp,is_active : 1};
      if (this.state.pickedTag != "others") obj.tags = JSON.stringify([this.state.pickedTag])
      this.createEditObject('create',null,obj,false);
      this.setState({addItem : false, data : []})
    }
  }

  handleOnInputChange = (event,value,reason) => {  
    if (reason == "clear" ) this.setState({showAlert : false, data : [] }); 
  }


  handleType = (values) => { 
    
    let url =  server + '/public/yahoofinance/search?input=' + values;
    if(values.length == 0)
      this.setState({data : []});
    else
    fetch(url)
      .then(response => response.json())
      .then(jsonResponse => { var text = document.getElementById("free-solo-demo").value;
                 if (jsonResponse.data != undefined && text != "" ) {

                  var l =  jsonResponse.data.items
                  .filter( x => { return x.exchDisp.match(/^(NYSE|NASDAQ|NSE|CCC|Industry|Shenzhen)$/) && x.type.match(/^(S|B)$/) })
                  .filter( x => { return  !x.symbol.includes("-BL") })
                  .filter( x => { return  !(x.exchDisp == "Industry" && !x.symbol.includes(".NS") ) })
                  this.setState({data : l});
                }
    })
  }
  scrollToBottom = () => {
    const { messageList } = this.refs;
    messageList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  render() {
  const { classes } = this.props;

  var lc = (this.props.lc == undefined) ? 'en' : this.props.lc;
  var p = new translator(lc,'/w/menu');
  return (
    <div>
      
    <AppBar position="relative" elevation={1} color="white">
    <Toolbar style={{ paddingLeft : "6px", paddingRight : "6px"}}>
    <Grid container direction="row" justify="flex-start" alignItems="center">
    
    <Grid item xs={5}>
    {this.state.exchanges.map(x => {
      return(
        <div style={{ marginLeft : "4px",marginRight : "4px", display: "inline", }} onClick={e => { var pe = this.state.pickedEx == x ? null : x; var t = (pe == null) ? this.state.pickedTag : null; this.setState({pickedEx : pe, pickedTag : t }); var pt = this.setTags(this.state.origlist,pe,"setexchange"); 
        this.setState({ list : this.getRefinedList(this.state.origlist,pe,pt) }); }} >
        <div style={ this.state.pickedEx == x ? { border: "1px solid black",   borderRadius : 4, fontSize : "14px",paddingLeft : "3px",paddingRight : "3px", paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
                                            : { borderRadius : 4, fontSize : "14px",paddingLeft : "3px",paddingRight : "3px",paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
        } children={exAb(x)} />
        </div>
      );
    })}
    </Grid>

    <Grid item xs={7}>
    <Grid container justify="flex-end" alignItems="center">
    {["W","M","Y","5Y"].map(x => {
      return(
        <div style={{  marginLeft : "2px",marginRight : "6px", display: "inline", }} onClick={e => {this.setState({period : x})}}>
        <div style={ this.state.period == x  ? { border: "1px solid black",   borderRadius : 4, fontSize : "14px",paddingLeft : "6px",paddingRight : "6px", paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
                                            : {   borderRadius : 4, fontSize : "14px",paddingLeft : "6px",paddingRight : "6px",paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
        } children={x} />
        </div>
      );
    })}
    { !this.state.addItem ? <AddCircleOutlineIcon fontSize="medium"  onClick={e=> this.setState({addItem : true })}/>
    : <CancelOutlinedIcon fontSize="medium"  onClick={e=> this.setState({addItem : false })}/>}
    </Grid>
    </Grid>

    
    </Grid>
    </Toolbar>
    </AppBar>
    
    { this.state.addItem &&
    <Searchbar data={this.state.data} handleType={this.handleType} handleOnChange={this.handleOnInputChange} handleOnChange={this.handleOnChange} />}

    { this.state.linI ? <LinearProgress color="primary" /> : 
    <div style={{ paddingLeft : "4px", paddingRight : "4px" }}>
    <Box pt="10px" pb="0px">
    <Grid container style={{ margin : 0, padding : 0}}>
    {this.state.tags.filter(x => { return x != null }).map(x => {
      return(
        <div style={{  marginLeft : "2px",marginRight : "6px",marginTop : "8px", display: "inline" }} onClick={e => { var t = (this.state.pickedTag == x) ? null : x;  this.setState({pickedTag : t}); 
        this.setState({ list : this.getRefinedList(this.state.origlist,this.state.pickedEx,t) }) }}>
        <div style={ this.state.pickedTag == x  ? { border: "1px solid black",   borderRadius : 4, fontSize : "13px",paddingLeft : "6px",paddingRight : "6px", paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
                                            : {   borderRadius : 4, fontSize : "13px",paddingLeft : "6px",paddingRight : "6px",paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
        } children={x.toUpperCase()} />
        </div>
      );
    })}
    </Grid>
    </Box>
    <Grid container spacing={0}  >
     { this.state.list
      .map( row => { //console.log(row);
        return(     
          <StockCellW pickedEx={this.state.pickedEx} displaySM={this.props.displaySM} db={this.props.db} row={row} period={this.state.period} page={this.props.page} tags={this.state.tags} createEditObject={this.createEditObject} createEditObjectTodo={this.createEditObjectTodo} />
        )
      })
     }
    <Box p={6}  />
    <Box p={1} ref="messageList"/>
   </Grid>
   <Box p={6}  />
   { this.state.list.length == 0 && 
    <Box p={6} >
    <Typography className={classes.noitems} align = "center" variant="subtitle1" color="textSecondary"   children={"No stocks added yet"} />
    </Box>}

   </div>
    }

   

   </div>

  );
  }
}

const PortfolioW = withStyles(useStyles)(X);
export default PortfolioW



