import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { server,cookies,local,parrot_key } from './components/environ';
import { init } from './components/parrot-js/parrot';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';
import ErrorBoundary   from  './ErrorBoundary';
import { Notifications } from 'react-push-notification';
import {theme} from './components/theme'
import StocksMainW from './components/vendor/stocksmain'

const UserVerified      = lazy(() => import('./components/vendor/signin/UserVerified'));
const ChangePassword    = lazy(() => import('./components/vendor/signin/ChangePassword'));
const StickyFooter      = lazy(() => import('./components/vendor/stickyfooter'));
const ErrorPage         = lazy(() => import('./components/vendor/signin/ErrorPage'));
const Privacy           = lazy(() => import('./components/vendor/signin/privacy'));
const Terms             = lazy(() => import('./components/vendor/signin/terms'));
const SignInForm        = lazy(() => import('./components/vendor/signin/SignInForm'));
const StaffSignInForm        = lazy(() => import('./components/vendor/signin/StaffSignInForm'));


function App() {
  
  //init(parrot_key);
  return (
    
    <ThemeProvider theme={theme}>
    <Notifications />
    <Router>
    <Suspense fallback={<div></div>}>
    <Switch>


        <Route  exact path="/" component={SignInForm} />
        <Route  exact path="/signin" component={SignInForm} />
        <Route  exact path="/staffsignin" component={StaffSignInForm} />
    
        <ErrorBoundary webaddress={window.location.href}>
        <Route  path="/dashboard"       component={StocksMainW} />
        <Route  path="/changepassword"  component={ChangePassword} />
        <Route  path="/userverified"  component={UserVerified} />
        <Route  path="/errorpage"  component={ErrorPage} />
        <Route  path="/terms" component={Terms} />
        <Route  path="/privacy" component={Privacy} />
        <Route  path="/main"   component={StocksMainW}  />
        </ErrorBoundary>
           
    </Switch>
    </Suspense>
    </Router>
    </ThemeProvider>
  );
}

export default App


//ReactDOM.render(<App />, document.querySelector('#app'));


