import React from 'react';
import { Component,Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { server,cookies,help_email,blog_data } from '../environ';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Container from '@material-ui/core/Container';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import {translator} from  '../parrot-js/parrot';



const useStyles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
  },
  noitems: {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin : theme.spacing(0, 4),
  },
 
});

class Help extends Component {

  constructor(props){
      super(props)
      this.state = {
        open : false,
        list : [],
        modify_item : false,
        modify_item_data : [],
        activity : "list",
        lpOpen : false,
        cpOpen : false,
        name   : '',
        country_code : null,
        currency_code : null,
        language_code : null
      }
  }

 
  


 
  render() {
  const { classes } = this.props;
  
  var lc = (this.props.lc == undefined) ? 'en' : this.props.lc;
  var p = new translator(lc,'/w/help');
 

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Toolbar>  
          { (this.props.vendorData !=null) ?
          <IconButton edge="start" onClick={e => this.props.backFromVendor(e,'Restaurants',null) } >
          <ArrowBackIcon />
          </IconButton> : null } 
          <Typography variant="subtitle1" className={classes.title}>
          {(this.props.vendorData !=null) ? this.props.vendorData.vendor_name : null}
          </Typography>
        </Toolbar>
      </AppBar>
    <div className={classes.root}> 
    <Box p={2}></Box>
    { false ?
    <Typography className={classes.noitems} align = "center" variant="body1" color="black" children={p.t('For any issues, you can chat with us or send us an email at','/list/noitem/v2') + ' ' + help_email} />
    :
    <div>
    <Typography style={{marginBottom : "48px"}} align = "center" variant="body1" color="black" children={p.t('For any issues, you can chat with us or send us an email at','/list/noitem/v2') + ' ' + help_email} />
    { blog_data(p).length > 0 &&
    <Typography style={{marginBottom : "20px", marginLeft : "30px"}} variant="h6" color="black" children={"To Do Videos"} />}
    <Grid container spacing={1}  >
    { blog_data(p).map( x =>  {
      return(
      <Grid item xs={12} md={4}>
      <Box p={2}  style={{maxWidth : "270px", margin : "0 auto"}}>
      <Button style={{ borderRadius : "4px", textTransform : "none", textAlign : "left", marginLeft : 0, padding : "8px",  display : "block"}} 
      onClick= {e => window.open(x.link)}>
      <img style={{borderRadius : "4px", display : "block", maxWidth : "100%"}} src={x.picture} />
      <Typography variant="subtitle1" align="center"  children={x.title}/>
      </Button>
      </Box>
      </Grid>
    )
    })}
  </Grid>
  </div> 
  }
  </div>
    </div>
  );
  }
}

const HelpW = withStyles(useStyles)(Help);
export default HelpW


// Case left handling network errors
// Form dialog nomenclature
// Language based string accessibility
// To add abilities to modify restaurants

