
var querystring = require('querystring');

// global variables

var data = {}, keys_to_send = {}, app_identifier = '';
var base_language = 'en';
var statusOK = false;
var webUrl = 'https://api-pt.plumqr.com'; 


class translator {

	constructor(x,y,z = '') {
			this.requested_language = x;
		  this.preFix = y.toString();
		  this.returnNothing = (z == 'returnNothing') ? true : false;
	}

	t (default_value,key) { 

		if(!statusOK)
		{	if (window.parrot_data != undefined) 
				{ data = window.parrot_data; statusOK = true; console.log("Parrot Data Pre Received") }
			else if (this.returnNothing)
				return '';
		}

		if (statusOK && this.preFix != undefined && this.requested_language != undefined) {	
				key = this.preFix + key;
				return translate(default_value,key,this.requested_language);
		}
		else 
				return default_value; 
	}
}

function init(key){ console.log('Parrot Init') 
	
	app_identifier = key;
	
	fetchData ( (err,result) => {
		if(err) return console.log("[Will return default values only] " + err);

		var result = JSON.parse(result);

		if(result.status == "success"){
			data = result.data; statusOK = true; 
			setInterval(sendMissingKeys,10000);
		}
		else 
			return console.log("[Will return default values only] " + result.status + result.message);
	}); 
}


export {translator,init,statusOK};
//module.exports = {init : init, translator : translator}; 


function translate(default_value,key,requested_language){

	if (key.split('-').length > 1) 
	{	
		return default_value;
		console.log("avoid character - in parrot key")
	}


	var x = data[key];

	if (x == undefined){
		failKey(key,default_value);
		return default_value;
	}
	else {
		var y = data[key + '-' + requested_language]; var z = data[key + '-' + base_language];

		if (y == undefined || y == ''){ 		
			if(y == undefined) failReqL(key,requested_language);

			if (z == undefined || z == ''){
				if(z == undefined) failBaseL(key,base_language);
				return default_value;
			}
			else
				return z;
		}
		else 
			return y;
	}
}


function failKey(key,default_value){

	data[key] = default_value;
	keys_to_send[key] = default_value;
}

function failReqL(key,requested_language){

	data[key + '-' + requested_language] = '';
	keys_to_send[key + '-' + requested_language] = '';

}

function failBaseL(key,base_language){

	data[key + '-' + base_language] = '';
	keys_to_send[key + '-' + base_language] = '';

}

function fetchData (callback) {

	const request = require('request');

	var data = { app_key : app_identifier  }

	var options = { 
		method: 'post', 
		url: webUrl + '/read_data',
		body : querystring.stringify(data),
		headers: {	'Content-Type': 'application/x-www-form-urlencoded' },
	}

	request(options, (err, response, body) => {
		if(err) return callback(err);
		if(response.statusCode != 200) 
			return callback("fetchData Error : " + response.statusCode );

		return callback(null,body);
	});   	
}



function sendMissingKeys(){

	if (Object.keys(keys_to_send).length){
		
		const request = require('request');

		var data = { missing_keys : JSON.stringify(keys_to_send), app_key : app_identifier  }

		var options = { 
			method: 'post', 
 			url: webUrl + '/add_keys',
			body : querystring.stringify(data),
			headers: {	'Content-Type': 'application/x-www-form-urlencoded' },
		}

		request(options, (err, response, body) => {
			if(err) return console.log(err);

			keys_to_send = {};
		}); 
	}
}
