
export const isoCurrencies = {
    AED: {
      num: 784,
      places: 2,
      name: "United Arab Emirates dirham",
      symbol: "AED",
      countries: ["United Arab Emirates"]
    },
    AFN: {
      num: 971,
      places: 2,
      name: "Afghan afghani",
      symbol: "؋",
      countries: ["Afghanistan"]
    },
    ALL: {
      num: 8,
      places: 2,
      name: "Albanian lek",
      symbol: "L",
      countries: ["Albania"]
    },
    AMD: {
      num: 51,
      places: 2,
      name: "Armenian dram",
      symbol: "֏",
      countries: ["Armenia"]
    },
    ANG: {
      num: 532,
      places: 2,
      name: "Netherlands Antillean guilder",
      symbol: "NAƒ",
      countries: ["Curaçao (CW)", "Sint Maarten (SX)"]
    },
    AOA: {
      num: 973,
      places: 2,
      name: "Angolan kwanza",
      symbol: "Kz",
      countries: ["Angola"]
    },
    ARS: {
      num: 32,
      places: 2,
      name: "Argentine peso",
      symbol: "$",
      countries: ["Argentina"]
    },
    AUD: {
      num: 36,
      places: 2,
      name: "Australian dollar",
      symbol: "A$",
      countries: ["Australia", "Christmas Island (CX)", "Cocos (Keeling) Islands (CC)", "Heard Island and McDonald Islands (HM)", "Kiribati (KI)", "Nauru (NR)", "Norfolk Island (NF)", "Tuvalu (TV)", "Australian Antarctic Territory"]
    },
    AWG: {
      num: 533,
      places: 2,
      name: "Aruban florin",
      symbol: "Afl",
      countries: ["Aruba"]
    },
    AZN: {
      num: 944,
      places: 2,
      name: "Azerbaijani manat",
      symbol: "₼",
      countries: ["Azerbaijan"]
    },
    BAM: {
      num: 977,
      places: 2,
      name: "Bosnia and Herzegovina convertible mark",
      symbol: "KM",
      countries: ["Bosnia and Herzegovina"]
    },
    BBD: {
      num: 52,
      places: 2,
      name: "Barbados dollar",
      symbol: "Bds$",
      countries: ["Barbados"]
    },
    BDT: {
      num: 50,
      places: 2,
      name: "Bangladeshi taka",
      symbol: "৳",
      countries: ["Bangladesh"]
    },
    BGN: {
      num: 975,
      places: 2,
      name: "Bulgarian lev",
      symbol: "лв.",
      countries: ["Bulgaria"]
    },
    BHD: {
      num: 48,
      places: 3,
      name: "Bahraini dinar",
      symbol: "BD",
      countries: ["Bahrain"]
    },
    BIF: {
      num: 108,
      places: 0,
      name: "Burundian franc",
      symbol: "FBu",
      countries: ["Burundi"]
    },
    BMD: {
      num: 60,
      places: 2,
      name: "Bermudian dollar",
      symbol: "$",
      countries: ["Bermuda"]
    },
    BND: {
      num: 96,
      places: 2,
      name: "Brunei dollar",
      symbol: "B$",
      countries: ["Brunei", "auxiliary in Singapore (SG)"]
    },
    BOB: {
      num: 68,
      places: 2,
      name: "Boliviano",
      symbol: "Bs",
      countries: ["Bolivia"]
    },
    BOV: {
      num: 984,
      places: 2,
      name: "Bolivian Mvdol (funds code)",
      symbol: "",
      countries: ["Bolivia"]
    },
    BRL: {
      num: 986,
      places: 2,
      name: "Brazilian real",
      symbol: "R$",
      countries: ["Brazil"]
    },
    BSD: {
      num: 44,
      places: 2,
      name: "Bahamian dollar",
      symbol: "B$",
      countries: ["Bahamas"]
    },
    BTN: {
      num: 64,
      places: 2,
      name: "Bhutanese ngultrum",
      symbol: "Nu.",
      countries: ["Bhutan"]
    },
    BWP: {
      num: 72,
      places: 2,
      name: "Botswana pula",
      symbol: "P",
      countries: ["Botswana"]
    },
    BYN: {
      num: 933,
      places: 2,
      name: "Belarusian ruble",
      symbol: "Br",
      countries: ["Belarus"]
    },
    BYR: {
      num: 974,
      places: 0,
      name: "Belarusian ruble",
      symbol: "Br",
      countries: ["Belarus"]
    },
    BZD: {
      num: 84,
      places: 2,
      name: "Belize dollar",
      symbol: "BZ$",
      countries: ["Belize"]
    },
    CAD: {
      num: 124,
      places: 2,
      name: "Canadian dollar",
      symbol: "C$",
      countries: ["Canada"]
    },
    CDF: {
      num: 976,
      places: 2,
      name: "Congolese franc",
      symbol: "FC",
      countries: ["Democratic Republic of the Congo"]
    },
    CHE: {
      num: 947,
      places: 2,
      name: "WIR Euro (complementary currency)",
      symbol: "",
      countries: ["Switzerland"]
    },
    CHF: {
      num: 756,
      places: 2,
      name: "Swiss franc",
      symbol: "Fr.",
      countries: ["Switzerland", "Liechtenstein (LI)"]
    },
    CHW: {
      num: 948,
      places: 2,
      name: "WIR Franc (complementary currency)",
      symbol: "",
      countries: ["Switzerland"]
    },
    CLF: {
      num: 990,
      places: 4,
      name: "Unidad de Fomento (funds code)",
      symbol: "",
      countries: ["Chile"]
    },
    CLP: {
      num: 152,
      places: 0,
      name: "Chilean peso",
      symbol: "$",
      countries: ["Chile"]
    },
    CNY: {
      num: 156,
      places: 2,
      name: "Chinese yuan",
      symbol: "¥",
      countries: ["China"]
    },
    COP: {
      num: 170,
      places: 2,
      name: "Colombian peso",
      symbol: "$",
      countries: ["Colombia"]
    },
    COU: {
      num: 970,
      places: 2,
      name: "Unidad de Valor Real (UVR) (funds code)",
      symbol: "",
      countries: ["Colombia"]
    },
    CRC: {
      num: 188,
      places: 2,
      name: "Costa Rican colon",
      symbol: "₡",
      countries: ["Costa Rica"]
    },

    CVE: {
      num: 132,
      places: 0,
      name: "Cape Verde escudo",
      symbol: "$",
      countries: ["Cape Verde"]
    },
    CZK: {
      num: 203,
      places: 2,
      name: "Czech koruna",
      symbol: "Kč",
      countries: ["Czech Republic"]
    },
    DJF: {
      num: 262,
      places: 0,
      name: "Djiboutian franc",
      symbol: "Fdj",
      countries: ["Djibouti"]
    },
    DKK: {
      num: 208,
      places: 2,
      name: "Danish krone",
      symbol: "kr.",
      countries: ["Denmark", "Faroe Islands (FO)", "Greenland (GL)"]
    },
    DOP: {
      num: 214,
      places: 2,
      name: "Dominican peso",
      symbol: "RD$",
      countries: ["Dominican Republic"]
    },
    DZD: {
      num: 12,
      places: 2,
      name: "Algerian dinar",
      symbol: "DA",
      countries: ["Algeria"]
    },
    EGP: {
      num: 818,
      places: 2,
      name: "Egyptian pound",
      symbol: "E£",
      countries: ["Egypt", "auxiliary in Gaza Strip"]
    },
    ERN: {
      num: 232,
      places: 2,
      name: "Eritrean nakfa",
      symbol: "Nfk",
      countries: ["Eritrea"]
    },
    ETB: {
      num: 230,
      places: 2,
      name: "Ethiopian birr",
      symbol: "Br",
      countries: ["Ethiopia"]
    },
    EUR: {
      num: 978,
      places: 2,
      name: "Euro",
      symbol: "€",
      countries: ["Akrotiri and Dhekelia", "Andorra (AD)", "Austria (AT)", "Belgium (BE)", "Cyprus (CY)", "Estonia (EE)", "Finland (FI)", "France (FR)", "Germany (DE)", "Greece (GR)", "Guadeloupe (GP)", "Ireland (IE)", "Italy (IT)", "Kosovo", "Latvia (LV)", "Lithuania (LT)", "Luxembourg (LU)", "Malta (MT)", "Martinique (MQ)", "Mayotte (YT)", "Monaco (MC)", "Montenegro (ME)", "Netherlands (NL)", "Portugal (PT)", "Réunion (RE)", "Saint Barthélemy (BL)", "Saint Pierre and Miquelon (PM)", "San Marino (SM)", "Slovakia (SK)", "Slovenia (SI)", "Spain (ES)", "Vatican City (VA); see Eurozone"]
    },
    FJD: {
      num: 242,
      places: 2,
      name: "Fiji dollar",
      symbol: "FJ$",
      countries: ["Fiji"]
    },
    FKP: {
      num: 238,
      places: 2,
      name: "Falkland Islands pound",
      symbol: "£",
      countries: ["Falkland Islands (pegged to GBP 1:1)"]
    },
    GBP: {
      num: 826,
      places: 2,
      name: "Pound sterling",
      symbol: "£",
      countries: ["United Kingdom", "the Isle of Man (IM", "see Manx pound)", "Jersey (JE", "see Jersey pound)", "Guernsey (GG", "see Guernsey pound)", "South Georgia and the South Sandwich Islands (GS)", "British Indian Ocean Territory (IO) (also uses USD)", "Tristan da Cunha (SH-TA)", "and British Antarctic Territory"]
    },
    GEL: {
      num: 981,
      places: 2,
      name: "Georgian lari",
      symbol: "₾",
      countries: ["Georgia (except Abkhazia (GE-AB) and South Ossetia)"]
    },
    GHS: {
      num: 936,
      places: 2,
      name: "Ghanaian cedi",
      symbol: "GH₵",
      countries: ["Ghana"]
    },
    GIP: {
      num: 292,
      places: 2,
      name: "Gibraltar pound",
      symbol: "£",
      countries: ["Gibraltar (pegged to GBP 1:1)"]
    },
    GMD: {
      num: 270,
      places: 2,
      name: "Gambian dalasi",
      symbol: "D",
      countries: ["Gambia"]
    },
    GNF: {
      num: 324,
      places: 0,
      name: "Guinean franc",
      symbol: "FG",
      countries: ["Guinea"]
    },
    GTQ: {
      num: 320,
      places: 2,
      name: "Guatemalan quetzal",
      symbol: "Q",
      countries: ["Guatemala"]
    },
    GYD: {
      num: 328,
      places: 2,
      name: "Guyanese dollar",
      symbol: "G$",
      countries: ["Guyana"]
    },
    HKD: {
      num: 344,
      places: 2,
      name: "Hong Kong dollar",
      symbol: "HK$",
      countries: ["Hong Kong", "Macao (MO)"]
    },
    HNL: {
      num: 340,
      places: 2,
      name: "Honduran lempira",
      symbol: "L",
      countries: ["Honduras"]
    },
    HRK: {
      num: 191,
      places: 2,
      name: "Croatian kuna",
      symbol: "kn",
      countries: ["Croatia"]
    },
    HTG: {
      num: 332,
      places: 2,
      name: "Haitian gourde",
      symbol: "G",
      countries: ["Haiti"]
    },
    HUF: {
      num: 348,
      places: 2,
      name: "Hungarian forint",
      symbol: "Ft",
      countries: ["Hungary"]
    },
    IDR: {
      num: 360,
      places: 2,
      name: "Indonesian rupiah",
      symbol: "Rp",
      countries: ["Indonesia"]
    },
    ILS: {
      num: 376,
      places: 2,
      name: "Israeli new shekel",
      symbol: "₪",
      countries: ["Israel", "State of Palestine (PS)"]
    },
    INR: {
      num: 356,
      places: 2,
      name: "Indian rupee",
      symbol: "₹",
      countries: ["India", "Bhutan", "Nepal", "Zimbabwe"]
    },
    IQD: {
      num: 368,
      places: 3,
      name: "Iraqi dinar",
      symbol: "ع.د",
      countries: ["Iraq"]
    },
    ISK: {
      num: 352,
      places: 0,
      name: "Icelandic króna",
      symbol: "kr",
      countries: ["Iceland"]
    },
    JMD: {
      num: 388,
      places: 2,
      name: "Jamaican dollar",
      symbol: "$",
      countries: ["Jamaica"]
    },
    JOD: {
      num: 400,
      places: 3,
      name: "Jordanian dinar",
      symbol: "",
      countries: ["Jordan", "auxiliary in West Bank"]
    },
    JPY: {
      num: 392,
      places: 0,
      name: "Japanese yen",
      symbol: "¥",
      countries: ["Japan"]
    },
    KES: {
      num: 404,
      places: 2,
      name: "Kenyan shilling",
      symbol: "KSh",
      countries: ["Kenya"]
    },
    KGS: {
      num: 417,
      places: 2,
      name: "Kyrgyzstani som",
      symbol: "som",
      countries: ["Kyrgyzstan"]
    },
    KHR: {
      num: 116,
      places: 2,
      name: "Cambodian riel",
      symbol: "៛",
      countries: ["Cambodia"]
    },
    KMF: {
      num: 174,
      places: 0,
      name: "Comoro franc",
      symbol: "CF",
      countries: ["Comoros"]
    },
    KRW: {
      num: 410,
      places: 0,
      name: "South Korean won",
      symbol: "₩",
      countries: ["South Korea"]
    },
    KWD: {
      num: 414,
      places: 3,
      name: "Kuwaiti dinar",
      symbol: "د.ك",
      countries: ["Kuwait"]
    },
    KYD: {
      num: 136,
      places: 2,
      name: "Cayman Islands dollar",
      symbol: "$",
      countries: ["Cayman Islands"]
    },
    KZT: {
      num: 398,
      places: 2,
      name: "Kazakhstani tenge",
      symbol: "₸",
      countries: ["Kazakhstan"]
    },
    LAK: {
      num: 418,
      places: 2,
      name: "Lao kip",
      symbol: "₭",
      countries: ["Laos"]
    },
    LBP: {
      num: 422,
      places: 2,
      name: "Lebanese pound",
      symbol: "ل.ل.‎",
      countries: ["Lebanon"]
    },
    LKR: {
      num: 144,
      places: 2,
      name: "Sri Lankan rupee",
      symbol: "රු",
      countries: ["Sri Lanka"]
    },
    LRD: {
      num: 430,
      places: 2,
      name: "Liberian dollar",
      symbol: "L$",
      countries: ["Liberia"]
    },
    LSL: {
      num: 426,
      places: 2,
      name: "Lesotho loti",
      symbol: "M",
      countries: ["Lesotho"]
    },
    LYD: {
      num: 434,
      places: 3,
      name: "Libyan dinar",
      symbol: "LD",
      countries: ["Libya"]
    },
    MAD: {
      num: 504,
      places: 2,
      name: "Moroccan dirham",
      symbol: "MAD",
      countries: ["Morocco"]
    },
    MDL: {
      num: 498,
      places: 2,
      name: "Moldovan leu",
      symbol: "lei",
      countries: ["Moldova (except Transnistria)"]
    },
    MGA: {
      num: 969,
      places: 1,
      name: "Malagasy ariary",
      symbol: "Ar",
      countries: ["Madagascar"]
    },
    MKD: {
      num: 807,
      places: 2,
      name: "Macedonian denar",
      symbol: "ден",
      countries: ["Macedonia"]
    },
    MMK: {
      num: 104,
      places: 2,
      name: "Myanmar kyat",
      symbol: "K",
      countries: ["Myanmar"]
    },
    MNT: {
      num: 496,
      places: 2,
      name: "Mongolian tögrög",
      symbol: "₮",
      countries: ["Mongolia"]
    },
    MOP: {
      num: 446,
      places: 2,
      name: "Macanese pataca",
      symbol: "MOP$",
      countries: ["Macao"]
    },
    MRO: {
      num: 478,
      places: 1,
      name: "Mauritanian ouguiya",
      symbol: "UM",
      countries: ["Mauritania"]
    },
    MUR: {
      num: 480,
      places: 2,
      name: "Mauritian rupee",
      symbol: "₨",
      countries: ["Mauritius"]
    },
    MVR: {
      num: 462,
      places: 2,
      name: "Maldivian rufiyaa",
      symbol: "Rf",
      countries: ["Maldives"]
    },
    MWK: {
      num: 454,
      places: 2,
      name: "Malawian kwacha",
      symbol: "MK",
      countries: ["Malawi"]
    },
    MXN: {
      num: 484,
      places: 2,
      name: "Mexican peso",
      symbol: "Mex$",
      countries: ["Mexico"]
    },
    MXV: {
      num: 979,
      places: 2,
      name: "Mexican Unidad de Inversion (UDI) (funds code)",
      symbol: "",
      countries: ["Mexico"]
    },
    MYR: {
      num: 458,
      places: 2,
      name: "Malaysian ringgit",
      symbol: "RM",
      countries: ["Malaysia"]
    },
    MZN: {
      num: 943,
      places: 2,
      name: "Mozambican metical",
      symbol: "MT",
      countries: ["Mozambique"]
    },
    NAD: {
      num: 516,
      places: 2,
      name: "Namibian dollar",
      symbol: "N$",
      countries: ["Namibia"]
    },
    NGN: {
      num: 566,
      places: 2,
      name: "Nigerian naira",
      symbol: "₦",
      countries: ["Nigeria"]
    },
    NIO: {
      num: 558,
      places: 2,
      name: "Nicaraguan córdoba",
      symbol: "C$",
      countries: ["Nicaragua"]
    },
    NOK: {
      num: 578,
      places: 2,
      name: "Norwegian krone",
      symbol: "kr",
      countries: ["Norway", "Svalbard and Jan Mayen (SJ)", "Bouvet Island (BV)", "Queen Maud Land", "Peter I Island"]
    },
    NPR: {
      num: 524,
      places: 2,
      name: "Nepalese rupee",
      symbol: "रू",
      countries: ["Nepal"]
    },
    NZD: {
      num: 554,
      places: 2,
      name: "New Zealand dollar",
      symbol: "$",
      countries: ["New Zealand", "Cook Islands (CK)", "Niue (NU)", "Pitcairn Islands (PN; see also Pitcairn Islands dollar)", "Tokelau (TK)", "Ross Dependency"]
    },
    OMR: {
      num: 512,
      places: 3,
      name: "Omani rial",
      symbol: "ر.ع.",
      countries: ["Oman"]
    },
    PAB: {
      num: 590,
      places: 2,
      name: "Panamanian balboa",
      symbol: "B/.",
      countries: ["Panama"]
    },
    PEN: {
      num: 604,
      places: 2,
      name: "Peruvian Sol",
      symbol: "S/",
      countries: ["Peru"]
    },
    PGK: {
      num: 598,
      places: 2,
      name: "Papua New Guinean kina",
      symbol: "K",
      countries: ["Papua New Guinea"]
    },
    PHP: {
      num: 608,
      places: 2,
      name: "Philippine peso",
      symbol: "₱",
      countries: ["Philippines"]
    },
    PKR: {
      num: 586,
      places: 2,
      name: "Pakistani rupee",
      symbol: "Rupees",
      countries: ["Pakistan"]
    },
    PLN: {
      num: 985,
      places: 2,
      name: "Polish złoty",
      symbol: "zł",
      countries: ["Poland"]
    },
    PYG: {
      num: 600,
      places: 0,
      name: "Paraguayan guaraní",
      symbol: "₲",
      countries: ["Paraguay"]
    },
    QAR: {
      num: 634,
      places: 2,
      name: "Qatari riyal",
      symbol: "QR",
      countries: ["Qatar"]
    },
    RON: {
      num: 946,
      places: 2,
      name: "Romanian leu",
      symbol: "lei",
      countries: ["Romania"]
    },
    RSD: {
      num: 941,
      places: 2,
      name: "Serbian dinar",
      symbol: "",
      countries: ["Serbia"]
    },
    RUB: {
      num: 643,
      places: 2,
      name: "Russian ruble",
      symbol: "₽",
      countries: ["Russia", "Abkhazia (GE-AB)", "South Ossetia", "Crimea"]
    },
    RWF: {
      num: 646,
      places: 0,
      name: "Rwandan franc",
      symbol: "R₣",
      countries: ["Rwanda"]
    },
    SAR: {
      num: 682,
      places: 2,
      name: "Saudi riyal",
      symbol: "SR",
      countries: ["Saudi Arabia"]
    },
    SBD: {
      num: 90,
      places: 2,
      name: "Solomon Islands dollar",
      symbol: "SI$",
      countries: ["Solomon Islands"]
    },
    SCR: {
      num: 690,
      places: 2,
      name: "Seychelles rupee",
      symbol: "SR",
      countries: ["Seychelles"]
    },
    SDG: {
      num: 938,
      places: 2,
      name: "Sudanese pound",
      symbol: "ج.س.",
      countries: ["Sudan"]
    },
    SEK: {
      num: 752,
      places: 2,
      name: "Swedish krona/kronor",
      symbol: "kr",
      countries: ["Sweden"]
    },
    SGD: {
      num: 702,
      places: 2,
      name: "Singapore dollar",
      symbol: "S$",
      countries: ["Singapore", "auxiliary in Brunei (BN)"]
    },
    SHP: {
      num: 654,
      places: 2,
      name: "Saint Helena pound",
      symbol: "£",
      countries: ["Saint Helena (SH-SH)", "Ascension Island (SH-AC) (pegged to GBP 1:1)"]
    },
    SLL: {
      num: 694,
      places: 2,
      name: "Sierra Leonean leone",
      symbol: "Le",
      countries: ["Sierra Leone"]
    },
    SOS: {
      num: 706,
      places: 2,
      name: "Somali shilling",
      symbol: "Sh.",
      countries: ["Somalia (except Somaliland)"]
    },
    SRD: {
      num: 968,
      places: 2,
      name: "Surinamese dollar",
      symbol: "$",
      countries: ["Suriname"]
    },
    SSP: {
      num: 728,
      places: 2,
      name: "South Sudanese pound",
      symbol: "",
      countries: ["South Sudan"]
    },
    STD: {
      num: 678,
      places: 2,
      name: "São Tomé and Príncipe dobra",
      symbol: "",
      countries: ["São Tomé and Príncipe"]
    },
    SVC: {
      num: 222,
      places: 2,
      name: "Salvadoran colón",
      symbol: "₡",
      countries: ["El Salvador"]
    },
    SZL: {
      num: 748,
      places: 2,
      name: "Swazi lilangeni",
      symbol: "L",
      countries: ["Swaziland"]
    },
    THB: {
      num: 764,
      places: 2,
      name: "Thai baht",
      symbol: "฿",
      countries: ["Thailand", "Cambodia", "Myanmar", "Laos"]
    },
    TJS: {
      num: 972,
      places: 2,
      name: "Tajikistani somoni",
      symbol: "",
      countries: ["Tajikistan"]
    },
    TMT: {
      num: 934,
      places: 2,
      name: "Turkmenistani manat",
      symbol: "T",
      countries: ["Turkmenistan"]
    },
    TND: {
      num: 788,
      places: 3,
      name: "Tunisian dinar",
      symbol: "DT",
      countries: ["Tunisia"]
    },
    TOP: {
      num: 776,
      places: 2,
      name: "Tongan paʻanga",
      symbol: "T$",
      countries: ["Tonga"]
    },

    TTD: {
      num: 780,
      places: 2,
      name: "Trinidad and Tobago dollar",
      symbol: "TT$",
      countries: ["Trinidad and Tobago"]
    },
    TWD: {
      num: 901,
      places: 2,
      name: "New Taiwan dollar",
      symbol: "NT$",
      countries: ["Taiwan"]
    },
    TZS: {
      num: 834,
      places: 2,
      name: "Tanzanian shilling",
      symbol: "TSh",
      countries: ["Tanzania"]
    },
    UAH: {
      num: 980,
      places: 2,
      name: "Ukrainian hryvnia",
      symbol: "₴",
      countries: ["Ukraine"]
    },
    UGX: {
      num: 800,
      places: 0,
      name: "Ugandan shilling",
      symbol: "USh",
      countries: ["Uganda"]
    },
    USD: {
      num: 840,
      places: 2,
      name: "United States dollar",
      symbol: "$",
      countries: ["United States", "American Samoa (AS)", "Barbados (BB) (as well as Barbados Dollar)", "Bermuda (BM) (as well as Bermudian Dollar)", "British Indian Ocean Territory (IO) (also uses GBP)", "British Virgin Islands (VG)", "Caribbean Netherlands (BQ - Bonaire", "Sint Eustatius and Saba)", "Ecuador (EC)", "El Salvador (SV)", "Guam (GU)", "Haiti (HT)", "Marshall Islands (MH)", "Federated States of Micronesia (FM)", "Northern Mariana Islands (MP)", "Palau (PW)", "Panama (PA)", "Puerto Rico (PR)", "Timor-Leste (TL)", "Turks and Caicos Islands (TC)", "U.S. Virgin Islands (VI)", "Zimbabwe (ZW)"]
    },
    USN: {
      num: 997,
      places: 2,
      name: "United States dollar (next day) (funds code)",
      symbol: "",
      countries: ["United States"]
    },
    UYI: {
      num: 940,
      places: 0,
      name: "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)",
      symbol: "",
      countries: ["Uruguay"]
    },
    UYU: {
      num: 858,
      places: 2,
      name: "Uruguayan peso",
      symbol: "$U",
      countries: ["Uruguay"]
    },
    UZS: {
      num: 860,
      places: 2,
      name: "Uzbekistan som",
      symbol: "som",
      countries: ["Uzbekistan"]
    },
    VEF: {
      num: 937,
      places: 2,
      name: "Venezuelan bolívar",
      symbol: "Bs.F.",
      countries: ["Venezuela"]
    },
    VND: {
      num: 704,
      places: 0,
      name: "Vietnamese dong",
      symbol: "₫",
      countries: ["Vietnam"]
    },
    VUV: {
      num: 548,
      places: 0,
      name: "Vanuatu vatu",
      symbol: "VT",
      countries: ["Vanuatu"]
    },
    WST: {
      num: 882,
      places: 2,
      name: "Samoan tala",
      symbol: "WS$",
      countries: ["Samoa"]
    },
    XAF: {
      num: 950,
      places: 0,
      name: "CFA franc BEAC",
      symbol: "FCFA",
      countries: ["Cameroon (CM)", "Central African Republic (CF)", "Republic of the Congo (CG)", "Chad (TD)", "Equatorial Guinea (GQ)", "Gabon (GA)"]
    },
    XAG: {
      num: 961,
      places: -1,
      name: "Silver (one troy ounce)",
      symbol: "",
      countries: []
    },
    XAU: {
      num: 959,
      places: -1,
      name: "Gold (one troy ounce)",
      symbol: "",
      countries: []
    },
    XBA: {
      num: 955,
      places: -1,
      name: "European Composite Unit (EURCO) (bond market unit)",
      symbol: "",
      countries: []
    },
    XBB: {
      num: 956,
      places: -1,
      name: "European Monetary Unit (E.M.U.-6) (bond market unit)",
      symbol: "",
      countries: []
    },
    XBC: {
      num: 957,
      places: -1,
      name: "European Unit of Account 9 (E.U.A.-9) (bond market unit)",
      symbol: "",
      countries: []
    },
    XBD: {
      num: 958,
      places: -1,
      name: "European Unit of Account 17 (E.U.A.-17) (bond market unit)",
      symbol: "",
      countries: []
    },
    XCD: {
      num: 951,
      places: 2,
      name: "East Caribbean dollar",
      symbol: "$",
      countries: ["Anguilla (AI)", "Antigua and Barbuda (AG)", "Dominica (DM)", "Grenada (GD)", "Montserrat (MS)", "Saint Kitts and Nevis (KN)", "Saint Lucia (LC)", "Saint Vincent and the Grenadines (VC)"]
    },
    XDR: {
      num: 960,
      places: -1,
      name: "Special drawing rights",
      symbol: "SDR",
      countries: ["International Monetary Fund"]
    },
    XOF: {
      num: 952,
      places: 0,
      name: "CFA franc BCEAO",
      symbol: "CFA",
      countries: ["Benin (BJ)", "Burkina Faso (BF)", "Côte d'Ivoire (CI)", "Guinea-Bissau (GW)", "Mali (ML)", "Niger (NE)", "Senegal (SN)", "Togo (TG)"]
    },
    XPD: {
      num: 964,
      places: -1,
      name: "Palladium (one troy ounce)",
      symbol: "",
      countries: []
    },
    XPF: {
      num: 953,
      places: 0,
      name: "CFP franc (franc Pacifique)",
      symbol: "₣",
      countries: ["French territories of the Pacific Ocean: French Polynesia (PF)", "New Caledonia (NC)", "Wallis and Futuna (WF)"]
    },
    XPT: {
      num: 962,
      places: -1,
      name: "Platinum (one troy ounce)",
      symbol: "",
      countries: []
    },
    XSU: {
      num: 994,
      places: -1,
      name: "SUCRE",
      symbol: "Sucre",
      countries: ["Unified System for Regional Compensation (SUCRE)"]
    },
    XTS: {
      num: 963,
      places: -1,
      name: "Code reserved for testing purposes",
      symbol: "TEST",
      countries: []
    },
    XUA: {
      num: 965,
      places: -1,
      name: "ADB Unit of Account",
      symbol: "",
      countries: ["African Development Bank"]
    },
    XXX: {
      num: 999,
      places: -1,
      name: "No currency",
      symbol: "none",
      countries: []
    },
    YER: {
      num: 886,
      places: 2,
      name: "Yemeni rial",
      symbol: "﷼",
      countries: ["Yemen"]
    },
    ZAR: {
      num: 710,
      places: 2,
      name: "South African rand",
      symbol: "R",
      countries: ["South Africa"]
    },
    ZMW: {
      num: 967,
      places: 2,
      name: "Zambian kwacha",
      symbol: "ZK",
      countries: ["Zambia"]
    },
    ZWL: {
      num: 932,
      places: 2,
      name: "Zimbabwean dollar A/10",
      symbol: "Z$",
      countries: ["Zimbabwe"]
    },
};

export const cur_data = [
  {
    "code": "AED",
    "number": "784",
    "digits": 2,
    "currency": "UAE Dirham",
    "countries": [
      "United Arab Emirates (The)"
    ]
  },
  {
    "code": "AFN",
    "number": "971",
    "digits": 2,
    "currency": "Afghani",
    "countries": [
      "Afghanistan"
    ]
  },
  {
    "code": "ALL",
    "number": "008",
    "digits": 2,
    "currency": "Lek",
    "countries": [
      "Albania"
    ]
  },
  {
    "code": "AMD",
    "number": "051",
    "digits": 2,
    "currency": "Armenian Dram",
    "countries": [
      "Armenia"
    ]
  },
  {
    "code": "ANG",
    "number": "532",
    "digits": 2,
    "currency": "Netherlands Antillean Guilder",
    "countries": [
      "Curaçao",
      "Sint Maarten (Dutch Part)"
    ]
  },
  {
    "code": "AOA",
    "number": "973",
    "digits": 2,
    "currency": "Kwanza",
    "countries": [
      "Angola"
    ]
  },
  {
    "code": "ARS",
    "number": "032",
    "digits": 2,
    "currency": "Argentine Peso",
    "countries": [
      "Argentina"
    ]
  },
  {
    "code": "AUD",
    "number": "036",
    "digits": 2,
    "currency": "Australian Dollar",
    "countries": [
      "Australia",
      "Christmas Island",
      "Cocos (Keeling) Islands (The)",
      "Heard Island and Mcdonald Islands",
      "Kiribati",
      "Nauru",
      "Norfolk Island",
      "Tuvalu"
    ]
  },
  {
    "code": "AWG",
    "number": "533",
    "digits": 2,
    "currency": "Aruban Florin",
    "countries": [
      "Aruba"
    ]
  },
  {
    "code": "AZN",
    "number": "944",
    "digits": 2,
    "currency": "Azerbaijan Manat",
    "countries": [
      "Azerbaijan"
    ]
  },
  {
    "code": "BAM",
    "number": "977",
    "digits": 2,
    "currency": "Convertible Mark",
    "countries": [
      "Bosnia and Herzegovina"
    ]
  },
  {
    "code": "BBD",
    "number": "052",
    "digits": 2,
    "currency": "Barbados Dollar",
    "countries": [
      "Barbados"
    ]
  },
  {
    "code": "BDT",
    "number": "050",
    "digits": 2,
    "currency": "Taka",
    "countries": [
      "Bangladesh"
    ]
  },
  {
    "code": "BGN",
    "number": "975",
    "digits": 2,
    "currency": "Bulgarian Lev",
    "countries": [
      "Bulgaria"
    ]
  },
  {
    "code": "BHD",
    "number": "048",
    "digits": 3,
    "currency": "Bahraini Dinar",
    "countries": [
      "Bahrain"
    ]
  },
  {
    "code": "BIF",
    "number": "108",
    "digits": 0,
    "currency": "Burundi Franc",
    "countries": [
      "Burundi"
    ]
  },
  {
    "code": "BMD",
    "number": "060",
    "digits": 2,
    "currency": "Bermudian Dollar",
    "countries": [
      "Bermuda"
    ]
  },
  {
    "code": "BND",
    "number": "096",
    "digits": 2,
    "currency": "Brunei Dollar",
    "countries": [
      "Brunei Darussalam"
    ]
  },
  {
    "code": "BOB",
    "number": "068",
    "digits": 2,
    "currency": "Boliviano",
    "countries": [
      "Bolivia (Plurinational State Of)"
    ]
  },
  {
    "code": "BOV",
    "number": "984",
    "digits": 2,
    "currency": "Mvdol",
    "countries": [
      "Bolivia (Plurinational State Of)"
    ]
  },
  {
    "code": "BRL",
    "number": "986",
    "digits": 2,
    "currency": "Brazilian Real",
    "countries": [
      "Brazil"
    ]
  },
  {
    "code": "BSD",
    "number": "044",
    "digits": 2,
    "currency": "Bahamian Dollar",
    "countries": [
      "Bahamas (The)"
    ]
  },
  {
    "code": "BTN",
    "number": "064",
    "digits": 2,
    "currency": "Ngultrum",
    "countries": [
      "Bhutan"
    ]
  },
  {
    "code": "BWP",
    "number": "072",
    "digits": 2,
    "currency": "Pula",
    "countries": [
      "Botswana"
    ]
  },
  {
    "code": "BYN",
    "number": "933",
    "digits": 2,
    "currency": "Belarusian Ruble",
    "countries": [
      "Belarus"
    ]
  },
  {
    "code": "BZD",
    "number": "084",
    "digits": 2,
    "currency": "Belize Dollar",
    "countries": [
      "Belize"
    ]
  },
  {
    "code": "CAD",
    "number": "124",
    "digits": 2,
    "currency": "Canadian Dollar",
    "countries": [
      "Canada"
    ]
  },
  {
    "code": "CDF",
    "number": "976",
    "digits": 2,
    "currency": "Congolese Franc",
    "countries": [
      "Congo (The Democratic Republic of The)"
    ]
  },
  {
    "code": "CHE",
    "number": "947",
    "digits": 2,
    "currency": "WIR Euro",
    "countries": [
      "Switzerland"
    ]
  },
  {
    "code": "CHF",
    "number": "756",
    "digits": 2,
    "currency": "Swiss Franc",
    "countries": [
      "Liechtenstein",
      "Switzerland"
    ]
  },
  {
    "code": "CHW",
    "number": "948",
    "digits": 2,
    "currency": "WIR Franc",
    "countries": [
      "Switzerland"
    ]
  },
  {
    "code": "CLF",
    "number": "990",
    "digits": 4,
    "currency": "Unidad de Fomento",
    "countries": [
      "Chile"
    ]
  },
  {
    "code": "CLP",
    "number": "152",
    "digits": 0,
    "currency": "Chilean Peso",
    "countries": [
      "Chile"
    ]
  },
  {
    "code": "CNY",
    "number": "156",
    "digits": 2,
    "currency": "Yuan Renminbi",
    "countries": [
      "China"
    ]
  },
  {
    "code": "COP",
    "number": "170",
    "digits": 2,
    "currency": "Colombian Peso",
    "countries": [
      "Colombia"
    ]
  },
  {
    "code": "COU",
    "number": "970",
    "digits": 2,
    "currency": "Unidad de Valor Real",
    "countries": [
      "Colombia"
    ]
  },
  {
    "code": "CRC",
    "number": "188",
    "digits": 2,
    "currency": "Costa Rican Colon",
    "countries": [
      "Costa Rica"
    ]
  },
 
  {
    "code": "CVE",
    "number": "132",
    "digits": 2,
    "currency": "Cabo Verde Escudo",
    "countries": [
      "Cabo Verde"
    ]
  },
  {
    "code": "CZK",
    "number": "203",
    "digits": 2,
    "currency": "Czech Koruna",
    "countries": [
      "Czechia"
    ]
  },
  {
    "code": "DJF",
    "number": "262",
    "digits": 0,
    "currency": "Djibouti Franc",
    "countries": [
      "Djibouti"
    ]
  },
  {
    "code": "DKK",
    "number": "208",
    "digits": 2,
    "currency": "Danish Krone",
    "countries": [
      "Denmark",
      "Faroe Islands (The)",
      "Greenland"
    ]
  },
  {
    "code": "DOP",
    "number": "214",
    "digits": 2,
    "currency": "Dominican Peso",
    "countries": [
      "Dominican Republic (The)"
    ]
  },
  {
    "code": "DZD",
    "number": "012",
    "digits": 2,
    "currency": "Algerian Dinar",
    "countries": [
      "Algeria"
    ]
  },
  {
    "code": "EGP",
    "number": "818",
    "digits": 2,
    "currency": "Egyptian Pound",
    "countries": [
      "Egypt"
    ]
  },
  {
    "code": "ERN",
    "number": "232",
    "digits": 2,
    "currency": "Nakfa",
    "countries": [
      "Eritrea"
    ]
  },
  {
    "code": "ETB",
    "number": "230",
    "digits": 2,
    "currency": "Ethiopian Birr",
    "countries": [
      "Ethiopia"
    ]
  },
  {
    "code": "EUR",
    "number": "978",
    "digits": 2,
    "currency": "Euro",
    "countries": [
      "Åland Islands",
      "Andorra",
      "Austria",
      "Belgium",
      "Cyprus",
      "Estonia",
      "European Union",
      "Finland",
      "France",
      "French Guiana",
      "French Southern Territories (The)",
      "Germany",
      "Greece",
      "Guadeloupe",
      "Holy See (The)",
      "Ireland",
      "Italy",
      "Latvia",
      "Lithuania",
      "Luxembourg",
      "Malta",
      "Martinique",
      "Mayotte",
      "Monaco",
      "Montenegro",
      "Netherlands (The)",
      "Portugal",
      "Réunion",
      "Saint Barthélemy",
      "Saint Martin (French Part)",
      "Saint Pierre and Miquelon",
      "San Marino",
      "Slovakia",
      "Slovenia",
      "Spain"
    ]
  },
  {
    "code": "FJD",
    "number": "242",
    "digits": 2,
    "currency": "Fiji Dollar",
    "countries": [
      "Fiji"
    ]
  },
  {
    "code": "FKP",
    "number": "238",
    "digits": 2,
    "currency": "Falkland Islands Pound",
    "countries": [
      "Falkland Islands (The) [Malvinas]"
    ]
  },
  {
    "code": "GBP",
    "number": "826",
    "digits": 2,
    "currency": "Pound Sterling",
    "countries": [
      "Guernsey",
      "Isle of Man",
      "Jersey",
      "United Kingdom of Great Britain and Northern Ireland (The)"
    ]
  },
  {
    "code": "GEL",
    "number": "981",
    "digits": 2,
    "currency": "Lari",
    "countries": [
      "Georgia"
    ]
  },
  {
    "code": "GHS",
    "number": "936",
    "digits": 2,
    "currency": "Ghana Cedi",
    "countries": [
      "Ghana"
    ]
  },
  {
    "code": "GIP",
    "number": "292",
    "digits": 2,
    "currency": "Gibraltar Pound",
    "countries": [
      "Gibraltar"
    ]
  },
  {
    "code": "GMD",
    "number": "270",
    "digits": 2,
    "currency": "Dalasi",
    "countries": [
      "Gambia (The)"
    ]
  },
  {
    "code": "GNF",
    "number": "324",
    "digits": 0,
    "currency": "Guinean Franc",
    "countries": [
      "Guinea"
    ]
  },
  {
    "code": "GTQ",
    "number": "320",
    "digits": 2,
    "currency": "Quetzal",
    "countries": [
      "Guatemala"
    ]
  },
  {
    "code": "GYD",
    "number": "328",
    "digits": 2,
    "currency": "Guyana Dollar",
    "countries": [
      "Guyana"
    ]
  },
  {
    "code": "HKD",
    "number": "344",
    "digits": 2,
    "currency": "Hong Kong Dollar",
    "countries": [
      "Hong Kong"
    ]
  },
  {
    "code": "HNL",
    "number": "340",
    "digits": 2,
    "currency": "Lempira",
    "countries": [
      "Honduras"
    ]
  },
  {
    "code": "HRK",
    "number": "191",
    "digits": 2,
    "currency": "Kuna",
    "countries": [
      "Croatia"
    ]
  },
  {
    "code": "HTG",
    "number": "332",
    "digits": 2,
    "currency": "Gourde",
    "countries": [
      "Haiti"
    ]
  },
  {
    "code": "HUF",
    "number": "348",
    "digits": 2,
    "currency": "Forint",
    "countries": [
      "Hungary"
    ]
  },
  {
    "code": "IDR",
    "number": "360",
    "digits": 2,
    "currency": "Rupiah",
    "countries": [
      "Indonesia"
    ]
  },
  {
    "code": "ILS",
    "number": "376",
    "digits": 2,
    "currency": "New Israeli Sheqel",
    "countries": [
      "Israel"
    ]
  },
  {
    "code": "INR",
    "number": "356",
    "digits": 2,
    "currency": "Indian Rupee",
    "countries": [
      "Bhutan",
      "India"
    ]
  },
  {
    "code": "IQD",
    "number": "368",
    "digits": 3,
    "currency": "Iraqi Dinar",
    "countries": [
      "Iraq"
    ]
  },
  {
    "code": "ISK",
    "number": "352",
    "digits": 0,
    "currency": "Iceland Krona",
    "countries": [
      "Iceland"
    ]
  },
  {
    "code": "JMD",
    "number": "388",
    "digits": 2,
    "currency": "Jamaican Dollar",
    "countries": [
      "Jamaica"
    ]
  },
  {
    "code": "JOD",
    "number": "400",
    "digits": 3,
    "currency": "Jordanian Dinar",
    "countries": [
      "Jordan"
    ]
  },
  {
    "code": "JPY",
    "number": "392",
    "digits": 0,
    "currency": "Yen",
    "countries": [
      "Japan"
    ]
  },
  {
    "code": "KES",
    "number": "404",
    "digits": 2,
    "currency": "Kenyan Shilling",
    "countries": [
      "Kenya"
    ]
  },
  {
    "code": "KGS",
    "number": "417",
    "digits": 2,
    "currency": "Som",
    "countries": [
      "Kyrgyzstan"
    ]
  },
  {
    "code": "KHR",
    "number": "116",
    "digits": 2,
    "currency": "Riel",
    "countries": [
      "Cambodia"
    ]
  },
  {
    "code": "KMF",
    "number": "174",
    "digits": 0,
    "currency": "Comorian Franc ",
    "countries": [
      "Comoros (The)"
    ]
  },
  {
    "code": "KRW",
    "number": "410",
    "digits": 0,
    "currency": "Won",
    "countries": [
      "Korea (The Republic Of)"
    ]
  },
  {
    "code": "KWD",
    "number": "414",
    "digits": 3,
    "currency": "Kuwaiti Dinar",
    "countries": [
      "Kuwait"
    ]
  },
  {
    "code": "KYD",
    "number": "136",
    "digits": 2,
    "currency": "Cayman Islands Dollar",
    "countries": [
      "Cayman Islands (The)"
    ]
  },
  {
    "code": "KZT",
    "number": "398",
    "digits": 2,
    "currency": "Tenge",
    "countries": [
      "Kazakhstan"
    ]
  },
  {
    "code": "LAK",
    "number": "418",
    "digits": 2,
    "currency": "Lao Kip",
    "countries": [
      "Lao People’s Democratic Republic (The)"
    ]
  },
  {
    "code": "LBP",
    "number": "422",
    "digits": 2,
    "currency": "Lebanese Pound",
    "countries": [
      "Lebanon"
    ]
  },
  {
    "code": "LKR",
    "number": "144",
    "digits": 2,
    "currency": "Sri Lanka Rupee",
    "countries": [
      "Sri Lanka"
    ]
  },
  {
    "code": "LRD",
    "number": "430",
    "digits": 2,
    "currency": "Liberian Dollar",
    "countries": [
      "Liberia"
    ]
  },
  {
    "code": "LSL",
    "number": "426",
    "digits": 2,
    "currency": "Loti",
    "countries": [
      "Lesotho"
    ]
  },
  {
    "code": "LYD",
    "number": "434",
    "digits": 3,
    "currency": "Libyan Dinar",
    "countries": [
      "Libya"
    ]
  },
  {
    "code": "MAD",
    "number": "504",
    "digits": 2,
    "currency": "Moroccan Dirham",
    "countries": [
      "Morocco",
      "Western Sahara"
    ]
  },
  {
    "code": "MDL",
    "number": "498",
    "digits": 2,
    "currency": "Moldovan Leu",
    "countries": [
      "Moldova (The Republic Of)"
    ]
  },
  {
    "code": "MGA",
    "number": "969",
    "digits": 2,
    "currency": "Malagasy Ariary",
    "countries": [
      "Madagascar"
    ]
  },
  {
    "code": "MKD",
    "number": "807",
    "digits": 2,
    "currency": "Denar",
    "countries": [
      "Macedonia (The Former Yugoslav Republic Of)"
    ]
  },
  {
    "code": "MMK",
    "number": "104",
    "digits": 2,
    "currency": "Kyat",
    "countries": [
      "Myanmar"
    ]
  },
  {
    "code": "MNT",
    "number": "496",
    "digits": 2,
    "currency": "Tugrik",
    "countries": [
      "Mongolia"
    ]
  },
  {
    "code": "MOP",
    "number": "446",
    "digits": 2,
    "currency": "Pataca",
    "countries": [
      "Macao"
    ]
  },
  {
    "code": "MRU",
    "number": "929",
    "digits": 2,
    "currency": "Ouguiya",
    "countries": [
      "Mauritania"
    ]
  },
  {
    "code": "MUR",
    "number": "480",
    "digits": 2,
    "currency": "Mauritius Rupee",
    "countries": [
      "Mauritius"
    ]
  },
  {
    "code": "MVR",
    "number": "462",
    "digits": 2,
    "currency": "Rufiyaa",
    "countries": [
      "Maldives"
    ]
  },
  {
    "code": "MWK",
    "number": "454",
    "digits": 2,
    "currency": "Malawi Kwacha",
    "countries": [
      "Malawi"
    ]
  },
  {
    "code": "MXN",
    "number": "484",
    "digits": 2,
    "currency": "Mexican Peso",
    "countries": [
      "Mexico"
    ]
  },
  {
    "code": "MXV",
    "number": "979",
    "digits": 2,
    "currency": "Mexican Unidad de Inversion (UDI)",
    "countries": [
      "Mexico"
    ]
  },
  {
    "code": "MYR",
    "number": "458",
    "digits": 2,
    "currency": "Malaysian Ringgit",
    "countries": [
      "Malaysia"
    ]
  },
  {
    "code": "MZN",
    "number": "943",
    "digits": 2,
    "currency": "Mozambique Metical",
    "countries": [
      "Mozambique"
    ]
  },
  {
    "code": "NAD",
    "number": "516",
    "digits": 2,
    "currency": "Namibia Dollar",
    "countries": [
      "Namibia"
    ]
  },
  {
    "code": "NGN",
    "number": "566",
    "digits": 2,
    "currency": "Naira",
    "countries": [
      "Nigeria"
    ]
  },
  {
    "code": "NIO",
    "number": "558",
    "digits": 2,
    "currency": "Cordoba Oro",
    "countries": [
      "Nicaragua"
    ]
  },
  {
    "code": "NOK",
    "number": "578",
    "digits": 2,
    "currency": "Norwegian Krone",
    "countries": [
      "Bouvet Island",
      "Norway",
      "Svalbard and Jan Mayen"
    ]
  },
  {
    "code": "NPR",
    "number": "524",
    "digits": 2,
    "currency": "Nepalese Rupee",
    "countries": [
      "Nepal"
    ]
  },
  {
    "code": "NZD",
    "number": "554",
    "digits": 2,
    "currency": "New Zealand Dollar",
    "countries": [
      "Cook Islands (The)",
      "New Zealand",
      "Niue",
      "Pitcairn",
      "Tokelau"
    ]
  },
  {
    "code": "OMR",
    "number": "512",
    "digits": 3,
    "currency": "Rial Omani",
    "countries": [
      "Oman"
    ]
  },
  {
    "code": "PAB",
    "number": "590",
    "digits": 2,
    "currency": "Balboa",
    "countries": [
      "Panama"
    ]
  },
  {
    "code": "PEN",
    "number": "604",
    "digits": 2,
    "currency": "Sol",
    "countries": [
      "Peru"
    ]
  },
  {
    "code": "PGK",
    "number": "598",
    "digits": 2,
    "currency": "Kina",
    "countries": [
      "Papua New Guinea"
    ]
  },
  {
    "code": "PHP",
    "number": "608",
    "digits": 2,
    "currency": "Philippine Peso",
    "countries": [
      "Philippines (The)"
    ]
  },
  {
    "code": "PKR",
    "number": "586",
    "digits": 2,
    "currency": "Pakistan Rupee",
    "countries": [
      "Pakistan"
    ]
  },
  {
    "code": "PLN",
    "number": "985",
    "digits": 2,
    "currency": "Zloty",
    "countries": [
      "Poland"
    ]
  },
  {
    "code": "PYG",
    "number": "600",
    "digits": 0,
    "currency": "Guarani",
    "countries": [
      "Paraguay"
    ]
  },
  {
    "code": "QAR",
    "number": "634",
    "digits": 2,
    "currency": "Qatari Rial",
    "countries": [
      "Qatar"
    ]
  },
  {
    "code": "RON",
    "number": "946",
    "digits": 2,
    "currency": "Romanian Leu",
    "countries": [
      "Romania"
    ]
  },
  {
    "code": "RSD",
    "number": "941",
    "digits": 2,
    "currency": "Serbian Dinar",
    "countries": [
      "Serbia"
    ]
  },
  {
    "code": "RUB",
    "number": "643",
    "digits": 2,
    "currency": "Russian Ruble",
    "countries": [
      "Russian Federation (The)"
    ]
  },
  {
    "code": "RWF",
    "number": "646",
    "digits": 0,
    "currency": "Rwanda Franc",
    "countries": [
      "Rwanda"
    ]
  },
  {
    "code": "SAR",
    "number": "682",
    "digits": 2,
    "currency": "Saudi Riyal",
    "countries": [
      "Saudi Arabia"
    ]
  },
  {
    "code": "SBD",
    "number": "090",
    "digits": 2,
    "currency": "Solomon Islands Dollar",
    "countries": [
      "Solomon Islands"
    ]
  },
  {
    "code": "SCR",
    "number": "690",
    "digits": 2,
    "currency": "Seychelles Rupee",
    "countries": [
      "Seychelles"
    ]
  },
  {
    "code": "SDG",
    "number": "938",
    "digits": 2,
    "currency": "Sudanese Pound",
    "countries": [
      "Sudan (The)"
    ]
  },
  {
    "code": "SEK",
    "number": "752",
    "digits": 2,
    "currency": "Swedish Krona",
    "countries": [
      "Sweden"
    ]
  },
  {
    "code": "SGD",
    "number": "702",
    "digits": 2,
    "currency": "Singapore Dollar",
    "countries": [
      "Singapore"
    ]
  },
  {
    "code": "SHP",
    "number": "654",
    "digits": 2,
    "currency": "Saint Helena Pound",
    "countries": [
      "Saint Helena, Ascension and Tristan Da Cunha"
    ]
  },
  {
    "code": "SLL",
    "number": "694",
    "digits": 2,
    "currency": "Leone",
    "countries": [
      "Sierra Leone"
    ]
  },
  {
    "code": "SOS",
    "number": "706",
    "digits": 2,
    "currency": "Somali Shilling",
    "countries": [
      "Somalia"
    ]
  },
  {
    "code": "SRD",
    "number": "968",
    "digits": 2,
    "currency": "Surinam Dollar",
    "countries": [
      "Suriname"
    ]
  },
  {
    "code": "SSP",
    "number": "728",
    "digits": 2,
    "currency": "South Sudanese Pound",
    "countries": [
      "South Sudan"
    ]
  },
  {
    "code": "STN",
    "number": "930",
    "digits": 2,
    "currency": "Dobra",
    "countries": [
      "Sao Tome and Principe"
    ]
  },
  {
    "code": "SVC",
    "number": "222",
    "digits": 2,
    "currency": "El Salvador Colon",
    "countries": [
      "El Salvador"
    ]
  },
 
  {
    "code": "SZL",
    "number": "748",
    "digits": 2,
    "currency": "Lilangeni",
    "countries": [
      "Eswatini"
    ]
  },
  {
    "code": "THB",
    "number": "764",
    "digits": 2,
    "currency": "Baht",
    "countries": [
      "Thailand"
    ]
  },
  {
    "code": "TJS",
    "number": "972",
    "digits": 2,
    "currency": "Somoni",
    "countries": [
      "Tajikistan"
    ]
  },
  {
    "code": "TMT",
    "number": "934",
    "digits": 2,
    "currency": "Turkmenistan New Manat",
    "countries": [
      "Turkmenistan"
    ]
  },
  {
    "code": "TND",
    "number": "788",
    "digits": 3,
    "currency": "Tunisian Dinar",
    "countries": [
      "Tunisia"
    ]
  },
  {
    "code": "TOP",
    "number": "776",
    "digits": 2,
    "currency": "Pa’anga",
    "countries": [
      "Tonga"
    ]
  },

  {
    "code": "TTD",
    "number": "780",
    "digits": 2,
    "currency": "Trinidad and Tobago Dollar",
    "countries": [
      "Trinidad and Tobago"
    ]
  },
  {
    "code": "TWD",
    "number": "901",
    "digits": 2,
    "currency": "New Taiwan Dollar",
    "countries": [
      "Taiwan (Province of China)"
    ]
  },
  {
    "code": "TZS",
    "number": "834",
    "digits": 2,
    "currency": "Tanzanian Shilling",
    "countries": [
      "Tanzania, United Republic Of"
    ]
  },
  {
    "code": "UAH",
    "number": "980",
    "digits": 2,
    "currency": "Hryvnia",
    "countries": [
      "Ukraine"
    ]
  },
  {
    "code": "UGX",
    "number": "800",
    "digits": 0,
    "currency": "Uganda Shilling",
    "countries": [
      "Uganda"
    ]
  },
  {
    "code": "USD",
    "number": "840",
    "digits": 2,
    "currency": "US Dollar",
    "countries": [
      "American Samoa",
      "Bonaire, Sint Eustatius and Saba",
      "British Indian Ocean Territory (The)",
      "Ecuador",
      "El Salvador",
      "Guam",
      "Haiti",
      "Marshall Islands (The)",
      "Micronesia (Federated States Of)",
      "Northern Mariana Islands (The)",
      "Palau",
      "Panama",
      "Puerto Rico",
      "Timor-Leste",
      "Turks and Caicos Islands (The)",
      "United States Minor Outlying Islands (The)",
      "United States of America (The)",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)"
    ]
  },
  {
    "code": "USN",
    "number": "997",
    "digits": 2,
    "currency": "US Dollar (Next day)",
    "countries": [
      "United States of America (The)"
    ]
  },
  {
    "code": "UYI",
    "number": "940",
    "digits": 0,
    "currency": "Uruguay Peso en Unidades Indexadas (UI)",
    "countries": [
      "Uruguay"
    ]
  },
  {
    "code": "UYU",
    "number": "858",
    "digits": 2,
    "currency": "Peso Uruguayo",
    "countries": [
      "Uruguay"
    ]
  },
  {
    "code": "UYW",
    "number": "927",
    "digits": 4,
    "currency": "Unidad Previsional",
    "countries": [
      "Uruguay"
    ]
  },
  {
    "code": "UZS",
    "number": "860",
    "digits": 2,
    "currency": "Uzbekistan Sum",
    "countries": [
      "Uzbekistan"
    ]
  },
  {
    "code": "VES",
    "number": "928",
    "digits": 2,
    "currency": "Bolívar Soberano",
    "countries": [
      "Venezuela (Bolivarian Republic Of)"
    ]
  },
  {
    "code": "VND",
    "number": "704",
    "digits": 0,
    "currency": "Dong",
    "countries": [
      "Viet Nam"
    ]
  },
  {
    "code": "VUV",
    "number": "548",
    "digits": 0,
    "currency": "Vatu",
    "countries": [
      "Vanuatu"
    ]
  },
  {
    "code": "WST",
    "number": "882",
    "digits": 2,
    "currency": "Tala",
    "countries": [
      "Samoa"
    ]
  },
  {
    "code": "XAF",
    "number": "950",
    "digits": 0,
    "currency": "CFA Franc BEAC",
    "countries": [
      "Cameroon",
      "Central African Republic (The)",
      "Chad",
      "Congo (The)",
      "Equatorial Guinea",
      "Gabon"
    ]
  },
  {
    "code": "XAG",
    "number": "961",
    "digits": 0,
    "currency": "Silver",
    "countries": [
      "Zz11_silver"
    ]
  },
  {
    "code": "XAU",
    "number": "959",
    "digits": 0,
    "currency": "Gold",
    "countries": [
      "Zz08_gold"
    ]
  },
  {
    "code": "XBA",
    "number": "955",
    "digits": 0,
    "currency": "Bond Markets Unit European Composite Unit (EURCO)",
    "countries": [
      "Zz01_bond Markets Unit European_eurco"
    ]
  },
  {
    "code": "XBB",
    "number": "956",
    "digits": 0,
    "currency": "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
    "countries": [
      "Zz02_bond Markets Unit European_emu-6"
    ]
  },
  {
    "code": "XBC",
    "number": "957",
    "digits": 0,
    "currency": "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
    "countries": [
      "Zz03_bond Markets Unit European_eua-9"
    ]
  },
  {
    "code": "XBD",
    "number": "958",
    "digits": 0,
    "currency": "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
    "countries": [
      "Zz04_bond Markets Unit European_eua-17"
    ]
  },
  {
    "code": "XCD",
    "number": "951",
    "digits": 2,
    "currency": "East Caribbean Dollar",
    "countries": [
      "Anguilla",
      "Antigua and Barbuda",
      "Dominica",
      "Grenada",
      "Montserrat",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines"
    ]
  },
  {
    "code": "XDR",
    "number": "960",
    "digits": 0,
    "currency": "SDR (Special Drawing Right)",
    "countries": [
      "International Monetary Fund (Imf) "
    ]
  },
  {
    "code": "XOF",
    "number": "952",
    "digits": 0,
    "currency": "CFA Franc BCEAO",
    "countries": [
      "Benin",
      "Burkina Faso",
      "Côte d'Ivoire",
      "Guinea-Bissau",
      "Mali",
      "Niger (The)",
      "Senegal",
      "Togo"
    ]
  },
  {
    "code": "XPD",
    "number": "964",
    "digits": 0,
    "currency": "Palladium",
    "countries": [
      "Zz09_palladium"
    ]
  },
  {
    "code": "XPF",
    "number": "953",
    "digits": 0,
    "currency": "CFP Franc",
    "countries": [
      "French Polynesia",
      "New Caledonia",
      "Wallis and Futuna"
    ]
  },
  {
    "code": "XPT",
    "number": "962",
    "digits": 0,
    "currency": "Platinum",
    "countries": [
      "Zz10_platinum"
    ]
  },
  {
    "code": "XSU",
    "number": "994",
    "digits": 0,
    "currency": "Sucre",
    "countries": [
      "Sistema Unitario De Compensacion Regional De Pagos \"Sucre\""
    ]
  },
  {
    "code": "XTS",
    "number": "963",
    "digits": 0,
    "currency": "Codes specifically reserved for testing purposes",
    "countries": [
      "Zz06_testing_code"
    ]
  },
  {
    "code": "XUA",
    "number": "965",
    "digits": 0,
    "currency": "ADB Unit of Account",
    "countries": [
      "Member Countries of the African Development Bank Group"
    ]
  },
  {
    "code": "XXX",
    "number": "999",
    "digits": 0,
    "currency": "The codes assigned for transactions where no currency is involved",
    "countries": [
      "Zz07_no_currency"
    ]
  },
  {
    "code": "YER",
    "number": "886",
    "digits": 2,
    "currency": "Yemeni Rial",
    "countries": [
      "Yemen"
    ]
  },
  {
    "code": "ZAR",
    "number": "710",
    "digits": 2,
    "currency": "Rand",
    "countries": [
      "Lesotho",
      "Namibia",
      "South Africa"
    ]
  },
  {
    "code": "ZMW",
    "number": "967",
    "digits": 2,
    "currency": "Zambian Kwacha",
    "countries": [
      "Zambia"
    ]
  },
  {
    "code": "ZWL",
    "number": "932",
    "digits": 2,
    "currency": "Zimbabwe Dollar",
    "countries": [
      "Zimbabwe"
    ]
  }
];

export const cur_con = [
  { "country": "AD", "currency": "EUR" },
  { "country": "AE", "currency": "AED" },
  { "country": "AF", "currency": "AFN" },
  { "country": "AG", "currency": "XCD" },
  { "country": "AI", "currency": "XCD" },
  { "country": "AL", "currency": "ALL" },
  { "country": "AM", "currency": "AMD" },
  { "country": "AO", "currency": "AOA" },
  { "country": "AQ", "currency": "USD" },
  { "country": "AR", "currency": "ARS" },
  { "country": "AS", "currency": "USD" },
  { "country": "AT", "currency": "EUR" },
  { "country": "AU", "currency": "AUD" },
  { "country": "AW", "currency": "AWG" },
  { "country": "AX", "currency": "EUR" },
  { "country": "AZ", "currency": "AZN" },
  { "country": "BA", "currency": "BAM" },
  { "country": "BB", "currency": "BBD" },
  { "country": "BD", "currency": "BDT" },
  { "country": "BE", "currency": "EUR" },
  { "country": "BF", "currency": "XOF" },
  { "country": "BG", "currency": "BGN" },
  { "country": "BH", "currency": "BHD" },
  { "country": "BI", "currency": "BIF" },
  { "country": "BJ", "currency": "XOF" },
  { "country": "BL", "currency": "EUR" },
  { "country": "BM", "currency": "BMD" },
  { "country": "BN", "currency": "BND" },
  { "country": "BO", "currency": "BOB" },
  { "country": "BQ", "currency": "USD" },
  { "country": "BR", "currency": "BRL" },
  { "country": "BS", "currency": "BSD" },
  { "country": "BT", "currency": "INR" },
  { "country": "BV", "currency": "NOK" },
  { "country": "BW", "currency": "BWP" },
  { "country": "BY", "currency": "BYN" },
  { "country": "BZ", "currency": "BZD" },
  { "country": "CA", "currency": "CAD" },
  { "country": "CC", "currency": "AUD" },
  { "country": "CD", "currency": "CDF" },
  { "country": "CF", "currency": "XAF" },
  { "country": "CG", "currency": "XAF" },
  { "country": "CH", "currency": "CHF" },
  { "country": "CI", "currency": "XOF" },
  { "country": "CK", "currency": "NZD" },
  { "country": "CL", "currency": "CLP" },
  { "country": "CM", "currency": "XAF" },
  { "country": "CN", "currency": "CNY" },
  { "country": "CO", "currency": "COP" },
  { "country": "CR", "currency": "CRC" },
  { "country": "CU", "currency": "CUP" },
  { "country": "CV", "currency": "CVE" },
  { "country": "CW", "currency": "ANG" },
  { "country": "CX", "currency": "AUD" },
  { "country": "CY", "currency": "EUR" },
  { "country": "CZ", "currency": "CZK" },
  { "country": "DE", "currency": "EUR" },
  { "country": "DJ", "currency": "DJF" },
  { "country": "DK", "currency": "DKK" },
  { "country": "DM", "currency": "XCD" },
  { "country": "DO", "currency": "DOP" },
  { "country": "DZ", "currency": "DZD" },
  { "country": "EC", "currency": "USD" },
  { "country": "EE", "currency": "EUR" },
  { "country": "EG", "currency": "EGP" },
  { "country": "EH", "currency": "MAD" },
  { "country": "ER", "currency": "ERN" },
  { "country": "ES", "currency": "EUR" },
  { "country": "ET", "currency": "ETB" },
  { "country": "FI", "currency": "EUR" },
  { "country": "FJ", "currency": "FJD" },
  { "country": "FK", "currency": "FKP" },
  { "country": "FM", "currency": "USD" },
  { "country": "FO", "currency": "DKK" },
  { "country": "FR", "currency": "EUR" },
  { "country": "GA", "currency": "XAF" },
  { "country": "GB", "currency": "GBP" },
  { "country": "GD", "currency": "XCD" },
  { "country": "GE", "currency": "GEL" },
  { "country": "GF", "currency": "EUR" },
  { "country": "GG", "currency": "GGP" },
  { "country": "GH", "currency": "GHS" },
  { "country": "GI", "currency": "GIP" },
  { "country": "GL", "currency": "DKK" },
  { "country": "GM", "currency": "GMD" },
  { "country": "GN", "currency": "GNF" },
  { "country": "GP", "currency": "EUR" },
  { "country": "GQ", "currency": "XAF" },
  { "country": "GR", "currency": "EUR" },
  { "country": "GS", "currency": "GBP" },
  { "country": "GT", "currency": "GTQ" },
  { "country": "GU", "currency": "USD" },
  { "country": "GW", "currency": "XOF" },
  { "country": "GY", "currency": "GYD" },
  { "country": "HK", "currency": "HKD" },
  { "country": "HM", "currency": "AUD" },
  { "country": "HN", "currency": "HNL" },
  { "country": "HR", "currency": "HRK" },
  { "country": "HT", "currency": "HTG" },
  { "country": "HU", "currency": "HUF" },
  { "country": "ID", "currency": "IDR" },
  { "country": "IE", "currency": "EUR" },
  { "country": "IL", "currency": "ILS" },
  { "country": "IM", "currency": "GBP" },
  { "country": "IN", "currency": "INR" },
  { "country": "IO", "currency": "USD" },
  { "country": "IQ", "currency": "IQD" },
  { "country": "IR", "currency": "IRR" },
  { "country": "IS", "currency": "ISK" },
  { "country": "IT", "currency": "EUR" },
  { "country": "JE", "currency": "GBP" },
  { "country": "JM", "currency": "JMD" },
  { "country": "JO", "currency": "JOD" },
  { "country": "JP", "currency": "JPY" },
  { "country": "KE", "currency": "KES" },
  { "country": "KG", "currency": "KGS" },
  { "country": "KH", "currency": "KHR" },
  { "country": "KI", "currency": "AUD" },
  { "country": "KM", "currency": "KMF" },
  { "country": "KN", "currency": "XCD" },
  { "country": "KP", "currency": "KPW" },
  { "country": "KR", "currency": "KRW" },
  { "country": "KW", "currency": "KWD" },
  { "country": "KY", "currency": "KYD" },
  { "country": "KZ", "currency": "KZT" },
  { "country": "LA", "currency": "LAK" },
  { "country": "LB", "currency": "LBP" },
  { "country": "LC", "currency": "XCD" },
  { "country": "LI", "currency": "CHF" },
  { "country": "LK", "currency": "LKR" },
  { "country": "LR", "currency": "LRD" },
  { "country": "LS", "currency": "LSL" },
  { "country": "LT", "currency": "EUR" },
  { "country": "LU", "currency": "EUR" },
  { "country": "LV", "currency": "EUR" },
  { "country": "LY", "currency": "LYD" },
  { "country": "MA", "currency": "MAD" },
  { "country": "MC", "currency": "EUR" },
  { "country": "MD", "currency": "MDL" },
  { "country": "ME", "currency": "EUR" },
  { "country": "MF", "currency": "EUR" },
  { "country": "MG", "currency": "MGA" },
  { "country": "MH", "currency": "USD" },
  { "country": "MK", "currency": "MKD" },
  { "country": "ML", "currency": "XOF" },
  { "country": "MM", "currency": "MMK" },
  { "country": "MN", "currency": "MNT" },
  { "country": "MO", "currency": "MOP" },
  { "country": "MP", "currency": "USD" },
  { "country": "MQ", "currency": "EUR" },
  { "country": "MR", "currency": "MRO" },
  { "country": "MS", "currency": "XCD" },
  { "country": "MT", "currency": "EUR" },
  { "country": "MU", "currency": "MUR" },
  { "country": "MV", "currency": "MVR" },
  { "country": "MW", "currency": "MWK" },
  { "country": "MX", "currency": "MXN" },
  { "country": "MY", "currency": "MYR" },
  { "country": "MZ", "currency": "MZN" },
  { "country": "NA", "currency": "NAD" },
  { "country": "NC", "currency": "XPF" },
  { "country": "NE", "currency": "XOF" },
  { "country": "NF", "currency": "AUD" },
  { "country": "NG", "currency": "NGN" },
  { "country": "NI", "currency": "NIO" },
  { "country": "NL", "currency": "EUR" },
  { "country": "NO", "currency": "NOK" },
  { "country": "NP", "currency": "NPR" },
  { "country": "NR", "currency": "AUD" },
  { "country": "NU", "currency": "NZD" },
  { "country": "NZ", "currency": "NZD" },
  { "country": "OM", "currency": "OMR" },
  { "country": "PA", "currency": "PAB" },
  { "country": "PE", "currency": "PEN" },
  { "country": "PF", "currency": "XPF" },
  { "country": "PG", "currency": "PGK" },
  { "country": "PH", "currency": "PHP" },
  { "country": "PK", "currency": "PKR" },
  { "country": "PL", "currency": "PLN" },
  { "country": "PM", "currency": "EUR" },
  { "country": "PN", "currency": "NZD" },
  { "country": "PR", "currency": "USD" },
  { "country": "PS", "currency": "JOD" },
  { "country": "PT", "currency": "EUR" },
  { "country": "PW", "currency": "USD" },
  { "country": "PY", "currency": "PYG" },
  { "country": "QA", "currency": "QAR" },
  { "country": "RE", "currency": "EUR" },
  { "country": "RO", "currency": "RON" },
  { "country": "RS", "currency": "RSD" },
  { "country": "RU", "currency": "RUB" },
  { "country": "RW", "currency": "RWF" },
  { "country": "SA", "currency": "SAR" },
  { "country": "SB", "currency": "SBD" },
  { "country": "SC", "currency": "SCR" },
  { "country": "SD", "currency": "SDG" },
  { "country": "SE", "currency": "SEK" },
  { "country": "SG", "currency": "SGD" },
  { "country": "SH", "currency": "SHP" },
  { "country": "SI", "currency": "EUR" },
  { "country": "SJ", "currency": "NOK" },
  { "country": "SK", "currency": "EUR" },
  { "country": "SL", "currency": "SLL" },
  { "country": "SM", "currency": "EUR" },
  { "country": "SN", "currency": "XOF" },
  { "country": "SO", "currency": "SOS" },
  { "country": "SR", "currency": "SRD" },
  { "country": "SS", "currency": "SSP" },
  { "country": "ST", "currency": "STD" },
  { "country": "SV", "currency": "USD" },
  { "country": "SX", "currency": "ANG" },
  { "country": "SY", "currency": "SYP" },
  { "country": "SZ", "currency": "SZL" },
  { "country": "TC", "currency": "USD" },
  { "country": "TD", "currency": "XAF" },
  { "country": "TF", "currency": "EUR" },
  { "country": "TG", "currency": "XOF" },
  { "country": "TH", "currency": "THB" },
  { "country": "TJ", "currency": "TJS" },
  { "country": "TK", "currency": "NZD" },
  { "country": "TL", "currency": "USD" },
  { "country": "TM", "currency": "TMT" },
  { "country": "TN", "currency": "TND" },
  { "country": "TO", "currency": "TOP" },
  { "country": "TR", "currency": "TRY" },
  { "country": "TT", "currency": "TTD" },
  { "country": "TV", "currency": "AUD" },
  { "country": "TW", "currency": "TWD" },
  { "country": "TZ", "currency": "TZS" },
  { "country": "UA", "currency": "UAH" },
  { "country": "UG", "currency": "UGX" },
  { "country": "UM", "currency": "USD" },
  { "country": "US", "currency": "USD" },
  { "country": "UY", "currency": "UYU" },
  { "country": "UZ", "currency": "UZS" },
  { "country": "VA", "currency": "EUR" },
  { "country": "VC", "currency": "XCD" },
  { "country": "VE", "currency": "VEF" },
  { "country": "VG", "currency": "USD" },
  { "country": "VI", "currency": "USD" },
  { "country": "VN", "currency": "VND" },
  { "country": "VU", "currency": "VUV" },
  { "country": "WF", "currency": "XPF" },
  { "country": "WS", "currency": "EUR" },
  { "country": "YE", "currency": "YER" },
  { "country": "YT", "currency": "EUR" },
  { "country": "ZA", "currency": "ZAR" },
  { "country": "ZM", "currency": "ZMW" },
  { "country": "ZW", "currency": "USD" }
]

export const lang = [
      {"code":"ab","name":"Abkhaz","nativeName":"аҧсуа"},
      {"code":"aa","name":"Afar","nativeName":"Afaraf"},
      {"code":"af","name":"Afrikaans","nativeName":"Afrikaans"},
      {"code":"ak","name":"Akan","nativeName":"Akan"},
      {"code":"sq","name":"Albanian","nativeName":"Shqip"},
      {"code":"am","name":"Amharic","nativeName":"አማርኛ"},
      {"code":"ar","name":"Arabic","nativeName":"العربية"},
      {"code":"an","name":"Aragonese","nativeName":"Aragonés"},
      {"code":"hy","name":"Armenian","nativeName":"Հայերեն"},
      {"code":"as","name":"Assamese","nativeName":"অসমীয়া"},
      {"code":"av","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
      {"code":"ae","name":"Avestan","nativeName":"avesta"},
      {"code":"ay","name":"Aymara","nativeName":"aymar aru"},
      {"code":"az","name":"Azerbaijani","nativeName":"azərbaycan dili"},
      {"code":"bm","name":"Bambara","nativeName":"bamanankan"},
      {"code":"ba","name":"Bashkir","nativeName":"башҡорт теле"},
      {"code":"eu","name":"Basque","nativeName":"euskara, euskera"},
      {"code":"be","name":"Belarusian","nativeName":"Беларуская"},
      {"code":"bn","name":"Bengali","nativeName":"বাংলা"},
      {"code":"bh","name":"Bihari","nativeName":"भोजपुरी"},
      {"code":"bi","name":"Bislama","nativeName":"Bislama"},
      {"code":"bs","name":"Bosnian","nativeName":"bosanski jezik"},
      {"code":"br","name":"Breton","nativeName":"brezhoneg"},
      {"code":"bg","name":"Bulgarian","nativeName":"български език"},
      {"code":"my","name":"Burmese","nativeName":"ဗမာစာ"},
      {"code":"ca","name":"Catalan; Valencian","nativeName":"Català"},
      {"code":"ch","name":"Chamorro","nativeName":"Chamoru"},
      {"code":"ce","name":"Chechen","nativeName":"нохчийн мотт"},
      {"code":"ny","name":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
      {"code":"zh","name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
      {"code":"cv","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
      {"code":"kw","name":"Cornish","nativeName":"Kernewek"},
      {"code":"co","name":"Corsican","nativeName":"corsu, lingua corsa"},
      {"code":"cr","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
      {"code":"hr","name":"Croatian","nativeName":"hrvatski"},
      {"code":"cs","name":"Czech","nativeName":"česky, čeština"},
      {"code":"da","name":"Danish","nativeName":"dansk"},
      {"code":"dv","name":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
      {"code":"nl","name":"Dutch","nativeName":"Nederlands, Vlaams"},
      {"code":"en","name":"English","nativeName":"English"},
      {"code":"eo","name":"Esperanto","nativeName":"Esperanto"},
      {"code":"et","name":"Estonian","nativeName":"eesti, eesti keel"},
      {"code":"ee","name":"Ewe","nativeName":"Eʋegbe"},
      {"code":"fo","name":"Faroese","nativeName":"føroyskt"},
      {"code":"fj","name":"Fijian","nativeName":"vosa Vakaviti"},
      {"code":"fi","name":"Finnish","nativeName":"suomi, suomen kieli"},
      {"code":"fr","name":"French","nativeName":"français, langue française"},
      {"code":"ff","name":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
      {"code":"gl","name":"Galician","nativeName":"Galego"},
      {"code":"ka","name":"Georgian","nativeName":"ქართული"},
      {"code":"de","name":"German","nativeName":"Deutsch"},
      {"code":"el","name":"Greek, Modern","nativeName":"Ελληνικά"},
      {"code":"gn","name":"Guaraní","nativeName":"Avañeẽ"},
      {"code":"gu","name":"Gujarati","nativeName":"ગુજરાતી"},
      {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
      {"code":"ha","name":"Hausa","nativeName":"Hausa, هَوُسَ"},
      {"code":"he","name":"Hebrew (modern)","nativeName":"עברית"},
      {"code":"hz","name":"Herero","nativeName":"Otjiherero"},
      {"code":"hi","name":"Hindi","nativeName":"हिन्दी, हिंदी"},
      {"code":"ho","name":"Hiri Motu","nativeName":"Hiri Motu"},
      {"code":"hu","name":"Hungarian","nativeName":"Magyar"},
      {"code":"ia","name":"Interlingua","nativeName":"Interlingua"},
      {"code":"id","name":"Indonesian","nativeName":"Bahasa Indonesia"},
      {"code":"ie","name":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
      {"code":"ga","name":"Irish","nativeName":"Gaeilge"},
      {"code":"ig","name":"Igbo","nativeName":"Asụsụ Igbo"},
      {"code":"ik","name":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
      {"code":"io","name":"Ido","nativeName":"Ido"},
      {"code":"is","name":"Icelandic","nativeName":"Íslenska"},
      {"code":"it","name":"Italian","nativeName":"Italiano"},
      {"code":"iu","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
      {"code":"ja","name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
      {"code":"jv","name":"Javanese","nativeName":"basa Jawa"},
      {"code":"kl","name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
      {"code":"kn","name":"Kannada","nativeName":"ಕನ್ನಡ"},
      {"code":"kr","name":"Kanuri","nativeName":"Kanuri"},
      {"code":"ks","name":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
      {"code":"kk","name":"Kazakh","nativeName":"Қазақ тілі"},
      {"code":"km","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
      {"code":"ki","name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
      {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
      {"code":"ky","name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
      {"code":"kv","name":"Komi","nativeName":"коми кыв"},
      {"code":"kg","name":"Kongo","nativeName":"KiKongo"},
      {"code":"ko","name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
      {"code":"ku","name":"Kurdish","nativeName":"Kurdî, كوردی‎"},
      {"code":"kj","name":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
      {"code":"la","name":"Latin","nativeName":"latine, lingua latina"},
      {"code":"lb","name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
      {"code":"lg","name":"Luganda","nativeName":"Luganda"},
      {"code":"li","name":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
      {"code":"ln","name":"Lingala","nativeName":"Lingála"},
      {"code":"lo","name":"Lao","nativeName":"ພາສາລາວ"},
      {"code":"lt","name":"Lithuanian","nativeName":"lietuvių kalba"},
      {"code":"lu","name":"Luba-Katanga","nativeName":""},
      {"code":"lv","name":"Latvian","nativeName":"latviešu valoda"},
      {"code":"gv","name":"Manx","nativeName":"Gaelg, Gailck"},
      {"code":"mk","name":"Macedonian","nativeName":"македонски јазик"},
      {"code":"mg","name":"Malagasy","nativeName":"Malagasy fiteny"},
      {"code":"ms","name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
      {"code":"ml","name":"Malayalam","nativeName":"മലയാളം"},
      {"code":"mt","name":"Maltese","nativeName":"Malti"},
      {"code":"mi","name":"Māori","nativeName":"te reo Māori"},
      {"code":"mr","name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
      {"code":"mh","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
      {"code":"mn","name":"Mongolian","nativeName":"монгол"},
      {"code":"na","name":"Nauru","nativeName":"Ekakairũ Naoero"},
      {"code":"nv","name":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
      {"code":"nb","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
      {"code":"nd","name":"North Ndebele","nativeName":"isiNdebele"},
      {"code":"ne","name":"Nepali","nativeName":"नेपाली"},
      {"code":"ng","name":"Ndonga","nativeName":"Owambo"},
      {"code":"nn","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
      {"code":"no","name":"Norwegian","nativeName":"Norsk"},
      {"code":"ii","name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
      {"code":"nr","name":"South Ndebele","nativeName":"isiNdebele"},
      {"code":"oc","name":"Occitan","nativeName":"Occitan"},
      {"code":"oj","name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
      {"code":"cu","name":"Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
      {"code":"om","name":"Oromo","nativeName":"Afaan Oromoo"},
      {"code":"or","name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
      {"code":"os","name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
      {"code":"pa","name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
      {"code":"pi","name":"Pāli","nativeName":"पाऴि"},
      {"code":"fa","name":"Persian","nativeName":"فارسی"},
      {"code":"pl","name":"Polish","nativeName":"polski"},
      {"code":"ps","name":"Pashto, Pushto","nativeName":"پښتو"},
      {"code":"pt","name":"Portuguese","nativeName":"Português"},
      {"code":"qu","name":"Quechua","nativeName":"Runa Simi, Kichwa"},
      {"code":"rm","name":"Romansh","nativeName":"rumantsch grischun"},
      {"code":"rn","name":"Kirundi","nativeName":"kiRundi"},
      {"code":"ro","name":"Romanian, Moldavian, Moldovan","nativeName":"română"},
      {"code":"ru","name":"Russian","nativeName":"русский язык"},
      {"code":"sa","name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
      {"code":"sc","name":"Sardinian","nativeName":"sardu"},
      {"code":"sd","name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
      {"code":"se","name":"Northern Sami","nativeName":"Davvisámegiella"},
      {"code":"sm","name":"Samoan","nativeName":"gagana faa Samoa"},
      {"code":"sg","name":"Sango","nativeName":"yângâ tî sängö"},
      {"code":"sr","name":"Serbian","nativeName":"српски језик"},
      {"code":"gd","name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
      {"code":"sn","name":"Shona","nativeName":"chiShona"},
      {"code":"si","name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
      {"code":"sk","name":"Slovak","nativeName":"slovenčina"},
      {"code":"sl","name":"Slovene","nativeName":"slovenščina"},
      {"code":"so","name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
      {"code":"st","name":"Southern Sotho","nativeName":"Sesotho"},
      {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
      {"code":"su","name":"Sundanese","nativeName":"Basa Sunda"},
      {"code":"sw","name":"Swahili","nativeName":"Kiswahili"},
      {"code":"ss","name":"Swati","nativeName":"SiSwati"},
      {"code":"sv","name":"Swedish","nativeName":"svenska"},
      {"code":"ta","name":"Tamil","nativeName":"தமிழ்"},
      {"code":"te","name":"Telugu","nativeName":"తెలుగు"},
      {"code":"tg","name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
      {"code":"th","name":"Thai","nativeName":"ไทย"},
      {"code":"ti","name":"Tigrinya","nativeName":"ትግርኛ"},
      {"code":"bo","name":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
      {"code":"tk","name":"Turkmen","nativeName":"Türkmen, Түркмен"},
      {"code":"tl","name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
      {"code":"tn","name":"Tswana","nativeName":"Setswana"},
      {"code":"to","name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
      {"code":"tr","name":"Turkish","nativeName":"Türkçe"},
      {"code":"ts","name":"Tsonga","nativeName":"Xitsonga"},
      {"code":"tt","name":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
      {"code":"tw","name":"Twi","nativeName":"Twi"},
      {"code":"ty","name":"Tahitian","nativeName":"Reo Tahiti"},
      {"code":"ug","name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
      {"code":"uk","name":"Ukrainian","nativeName":"українська"},
      {"code":"ur","name":"Urdu","nativeName":"اردو"},
      {"code":"uz","name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
      {"code":"ve","name":"Venda","nativeName":"Tshivenḓa"},
      {"code":"vi","name":"Vietnamese","nativeName":"Tiếng Việt"},
      {"code":"vo","name":"Volapük","nativeName":"Volapük"},
      {"code":"wa","name":"Walloon","nativeName":"Walon"},
      {"code":"cy","name":"Welsh","nativeName":"Cymraeg"},
      {"code":"wo","name":"Wolof","nativeName":"Wollof"},
      {"code":"fy","name":"Western Frisian","nativeName":"Frysk"},
      {"code":"xh","name":"Xhosa","nativeName":"isiXhosa"},
      {"code":"yi","name":"Yiddish","nativeName":"ייִדיש"},
      {"code":"yo","name":"Yoruba","nativeName":"Yorùbá"},
      {"code":"za","name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
    ]

export const con = {
  "AF": "Afghanistan",
  "AX": "Aland Islands",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua And Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia And Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo, Democratic Republic",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "CI": "Cote D\"Ivoire",
  "HR": "Croatia",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (Malvinas)",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island & Mcdonald Islands",
  "VA": "Holy See (Vatican City State)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle Of Man",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KR": "Korea",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People\"s Democratic Republic",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libyan Arab Jamahiriya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia, Federated States Of",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestinian Territory, Occupied",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russian Federation",
  "RW": "Rwanda",
  "BL": "Saint Barthelemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts And Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin",
  "PM": "Saint Pierre And Miquelon",
  "VC": "Saint Vincent And Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome And Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia And Sandwich Isl.",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard And Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad And Tobago",
  "TN": "Tunisia",
  "TM": "Turkmenistan",
  "TC": "Turks And Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UM": "United States Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VN": "Vietnam",
  "VG": "Virgin Islands, British",
  "VI": "Virgin Islands, U.S.",
  "WF": "Wallis And Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
};

const googleApprovedLanguages = { "af" : true,
"sq" : true,
"am" : true,
"ar" : true,
"hy" : true,
"az" : true,
"eu" : true,
"be" : true,
"bn" : true,
"bs" : true,
"bg" : true,
"ca" : true,
"zh" : true,
"co" : true,
"hr" : true,
"cs" : true,
"da" : true,
"nl" : true,
"en" : true,
"eo" : true,
"et" : true,
"fi" : true,
"fr" : true,
"fy" : true,
"gl" : true,
"ka" : true,
"de" : true,
"el" : true,
"gu" : true,
"ht" : true,
"ha" : true,
"hi" : true,
"hu" : true,
"is" : true,
"ig" : true,
"id" : true,
"ga" : true,
"it" : true,
"ja" : true,
"jv" : true,
"kn" : true,
"kk" : true,
"km" : true,
"rw" : true,
"ko" : true,
"ku" : true,
"ky" : true,
"lo" : true,
"la" : true,
"lv" : true,
"lt" : true,
"lb" : true,
"mk" : true,
"mg" : true,
"ms" : true,
"ml" : true,
"mt" : true,
"mi" : true,
"mr" : true,
"mn" : true,
"my" : true,
"ne" : true,
"no" : true,
"ny" : true,
"or" : true,
"ps" : true,
"fa" : true,
"pl" : true,
"pt" : true,
"pa" : true,
"ro" : true,
"ru" : true,
"sm" : true,
"gd" : true,
"sr" : true,
"st" : true,
"sn" : true,
"sd" : true,
"si" : true,
"sk" : true,
"sl" : true,
"so" : true,
"es" : true,
"su" : true,
"sw" : true,
"sv" : true,
"tl" : true,
"tg" : true,
"ta" : true,
"tt" : true,
"te" : true,
"th" : true,
"tr" : true,
"tk" : true,
"uk" : true,
"ur" : true,
"ug" : true,
"uz" : true,
"vi" : true,
"cy" : true,
"xh" : true,
"yi" : true,
"yo" : true,
"zu" : true
}



export function getLanguageName(code){
  var y = lang.find(x => x.code == code );
  return (y != undefined) ? y.name : 'English';
}

export function getCountryName(code){
  var y = con[code];
  return (y != undefined) ? y : 'US';
}

export function getCurrencyName(code){
  var y = isoCurrencies[code];
  return (y != undefined) ? y.name : 'USD';
}

export function getCurrencySymbol(code){
  var y = isoCurrencies[code];
  return (y != undefined) ? y.symbol : '$';
}

export function getCountryList(){
  return Object.entries(con)
}

export function getLanguageList(){
  return lang.filter( x => {return googleApprovedLanguages[x.code]} );
}