import React, { Component } from 'react'
import { server,cookies,help_email } from './components/environ';


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    }
  }

  componentDidCatch(error, info) {

    console.log("Catching an error") // this is never logged
    var data = { error : error.toString(), info : null, user_id : cookies.get('/user/user_id'), url : this.props.webaddress };
    console.log(data); 

    data.access_token = cookies.get('/user/access_token');

    var options = {   method: 'POST',
                      headers: {'Content-Type': 'application/json'},
                      body: JSON.stringify(data) 
                  };

    let url = server + '/error/add'

    if (!window.location.href.includes("localhost"))
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
    });

    this.setState(state => ({...state, hasError: true }))
  }

  render() {
    
    if (this.state.hasError) {

      return <div children={"Something went wrong, it will get resolved on its own in sometime, if it persists please contact " + help_email} /> 
    
    }

    return this.props.children
  }
}

export default ErrorBoundary
