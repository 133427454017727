import React, { Suspense, lazy } from 'react';
import { Component,Fragment,useState } from 'react'

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import Divider from '@material-ui/core/Divider';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email } from '../environ';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Link from '@material-ui/core/Link';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,useTheme,
  createMuiTheme,
} from '@material-ui/core/styles';

import InputBase  from '@material-ui/core/InputBase';
import PortfolioW from './Portfolio'
import TodoW   from './Todo'
import FindAssetsW   from './FindAssets'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HelpW from  './Help';
const SettingsW        = lazy(() => import('./dashboard/Settings'));


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontWeight: 400,
      fontSize: 33.6,
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: 'rgb(0,0,0)',
      letterSpacing : '0.5px'
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
      //  backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  aroot: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  blockSection: {
    backgroundColor : "#cf6b4c"
  },
  paper: {
    background: '#F5F7F9',
    color: '#3B454E'
  },
  listItemDone: {
    borderRadius: "1em",
    "&,&:focus": {
      backgroundColor: "#F6F6E8",
      textDecoration: "line-through"
    },
    "&:hover": {
      textDecoration: "line-through"
    }
  },
  bbar: {
    width: 500,
    position : "absolute",
    bottom : 0
  },

});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);



function BottomBar (props) {
  
  return (
    <AppBar position="fixed" style={{ top: 'auto', bottom : 0, left : 0, right : 0}} color="white">
    <Box p={0} >
     <Grid container direction="row" justify="space-around" >
      <Button style={ props.page.includes("portfolio") ? { color : "blue"} : {}} onClick={e => props.setPage("page/portfolio")}>
      <Grid container direction="column" alignItems="center" >
      <ListAltOutlinedIcon />
       Portfolio 
      </Grid>
      </Button>
      <Button style={ props.page.includes("onwatch") ? { color : "blue"} : {}} onClick={e => props.setPage("page/onwatch")}>
      <Grid container direction="column" alignItems="center" >
      <FavoriteBorderOutlinedIcon />
       On watch 
      </Grid>
      </Button>
      <Button style={ props.page.includes("findassets") ? { color : "blue"} : {}} onClick={e => props.setPage("page/findassets")}>
      <Grid container direction="column" alignItems="center" >
      <ManageSearchIcon />
       Find Assets
      </Grid>
      </Button>
      {/*<Button style={ props.page.includes("todo") ? { color : "red"} : {}} onClick={e => props.setPage("page/todo")}>
      <Grid container direction="column" alignItems="center" >
      <WorkOutlineOutlinedIcon />
       To do 
      </Grid>
      </Button>*/}
    </Grid>
    </Box>
    </AppBar>
)

}

function TopBar (props) {
  return(
  <AppBar  position="static" color="primary" style={{ backgroundColor : "#1854f4"}}>
      <Toolbar>
      <MenuIcon edge="start" color="inherit" aria-label="menu" onClick={e => props.handleDrawer(true)} /> 

      <Typography variant="h6" style={{paddingLeft : "8px"}} className={props.classes.title}>
        StockMan
      </Typography>
      </Toolbar>
    </AppBar>
  );
}
 
function NavDrawer (props) { //console.log(props.drawerOpen)

  
  return (
    <Drawer open={props.drawerOpen==true} onClose={e => props.handleDrawer(false)} 
    style={{ paddingLeft : "8px"}} >
    <ChevronLeftIcon style={{ margin : "12px"}} onClick={e => props.handleDrawer(false)} />
    <List >
    { ["Home","Settings","Help","Logout"].map( x => {
      return (
        <ListItem style={{ width : "150px", paddingLeft : "20px"}} button onClick={e => props.backFromDrawer(x)}>
        <ListItemText primary={x} />
        </ListItem>
        )
    })
    }
    </List>
    </Drawer>
  )
}





const object_table = "tb_docs";

class X extends Component {

  constructor (props) {
      super(props);
      this.state = { 
        html : "",
        showEditor : false,
        list : [],
        selectedIndex : 0,
        page: "page/onwatch",
        db : null,
        displaySMopen : false
      };
  }

  componentDidMount(){ 

   var access_token = cookies.get('/user/access_token');

   if(access_token == undefined) 
    window.location.href = window.location.origin  + '/signin?signup=true'; 


   var clear = false;
   var today = (new Date()).toISOString().split('T')[0];
   if (localStorage.getItem("today") != today) {  clear = true; localStorage.setItem("today",today) }

   var db; 
   var r = window.indexedDB.open("db1", 5);
   r.onerror = function(event) { console.log("onerror",event) };
   r.onsuccess = (event) => { console.log("onsuccess",event)
      db = r.result;
      if (db.objectStoreNames.contains('xyz')) { console.log("ready")
        this.setState({ db : db })
        if (clear == true)
         { db.transaction(["xyz"],"readwrite").objectStore("xyz").clear(); console.log("clearing store") }
      }
      else
        console.log("not ready")
    };
    r.onupgradeneeded = (event) => { console.log('onupgradeneeded')
        db = r.result; 
        if (!db.objectStoreNames.contains('xyz')) db.createObjectStore("xyz", { keyPath: "key" });
    };

  }

  componentDidUpdate(prevProps,prevState) {   
  }

  setPage = (page) => {
    this.setState({ page : page})
  }

  displaySM = (msg) => {
    this.setState({ sMsg: msg, displaySMopen : true })
  }
  handledisplaySMClose = () => {
    this.setState({displaySMopen: false})
  }

  handleDrawer = (open) => {
    this.setState({drawerOpen : open})
  }

  backFromDrawer = (action) => {
    this.setState({drawerOpen : false})
    if (action == "Logout") this.logOutCalled();
    if (action == "Settings") this.setState({page : "page/settings"})
    if (action == "Home") this.setState({page : "page/portfolio"})
    if (action == "Help") this.setState({page : "page/help"})

  }

  logOutCalled = (e) => {
    var url = server + '/logout' ;     
    let data = { access_token: cookies.get('/user/access_token'), device_token: cookies.get('/user/device_token') };
    var options = { method: 'POST',headers: { 'Content-Type': 'application/json;charset=utf-8' },body: JSON.stringify(data) };
    
    fetch(url,options)
     .then(res=>res.json())
     .then(res => {
       Object.entries(cookies.getAll()).map(obj => cookies.remove(obj[0]));
       localStorage.removeItem('/user/vendor_id');
       window.location.href = window.location.origin + '/signin';
     })
    .catch(function(err) {
       Object.entries(cookies.getAll()).map(obj => cookies.remove(obj[0]));
       localStorage.removeItem('/user/vendor_id');
       window.location.href = window.location.origin + '/signin'; 
    });
  }

  backFromSettings = (action) => {
    this.setState({page : "page/portfolio" })
  }




  render() {
  const { classes } = this.props;
  const { page } = this.state;

   return(
    <ThemeProvider theme={theme}>
    <Container disableGutters={true} maxWidth={false} >
    <NavDrawer drawerOpen={this.state.drawerOpen} handleDrawer={this.handleDrawer} backFromDrawer={this.backFromDrawer} /> 
    <TopBar classes={classes} handleDrawer={this.handleDrawer} />
    <Suspense fallback={<div></div>}>

    { page == "page/portfolio" && <PortfolioW displaySM={this.displaySM} page={page} db={this.state.db} object_table={"tb_portfolio"} />}
    { page == "page/onwatch" && <PortfolioW displaySM={this.displaySM} page={page} db={this.state.db} object_table={"tb_watchlist"} />}
    { page == "page/findassets" && <FindAssetsW />}
    { page == "page/todo" && <TodoW />}
    { page == "page/settings" && <SettingsW page={page} backFromSettings={this.backFromSettings} />}
    { page == "page/help" && <HelpW page={page} />}

    </Suspense>
    <BottomBar page={page} setPage={this.setPage} />
    </Container>
     <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.displaySMopen}
        autoHideDuration={2000}
        onClose={e => this.handledisplaySMClose() }
        message={this.state.sMsg}
    />
    </ThemeProvider>

    );
  }
}

const StocksMainW = withStyles(useStyles)(X);
export default StocksMainW