
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';

var dark = 'light';

var t1 = {
  
   palette: {
    type: dark,
    primary: {
      main: '#5369d4', //moderate blue
    },
    secondary: {
      main: '#green', //red 
    },
    background: {
      paper: dark == 'dark' ? grey[800] : grey[50] ,
      default: dark == 'dark' ? grey[800] : grey[50] ,
    },

  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    h5: {
      letterSpacing: 0.5,
    },
 
    subtitle1: {
      fontWeight: 500,
      letterSpacing: 0.5,
    },
    body1: {
      letterSpacing: 0.5,
    },
    body2: {
      letterSpacing: 0.5,
    },
  }, 

}

export let theme = createMuiTheme({
  ...t1,
 
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.type == 'dark' ? grey[900] : grey[100] ,
      },
    },
    MuiAppBar: {
        colorTransparent: {
          backgroundColor:  theme.palette.type === 'dark' ? theme.palette.grey[700] : '#eaeff1',
        }
    },
    MuiButton: {
      label: {
   //     textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
 //       backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
 //       backgroundColor: theme.palette.background.drawer,
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    
  },
};


 
  export function themeGen (darkBool,ptColor,stColor,bgPaperLight,pttColor,sttColor,btColor,btColorS) {

   var dark = (darkBool == 'dark') ? 'dark' : 'light';

   bgPaperLight  = (bgPaperLight  != undefined)  ? bgPaperLight  : grey[50];
   var bgDrawerLight = (bgDrawerLight != undefined)  ? bgDrawerLight : grey[100]; 
   var bgAppBarLight = (bgAppBarLight != undefined)  ? bgAppBarLight : '#eaeff1';

   var stColor = (stColor != undefined) ? stColor : 'green';

   var primaryThemeColor = (ptColor  != undefined)  ? ptColor  : '#5369d4';

   const t1 = {
  
   palette: {
    type: dark,
    primary: {
      main: ptColor, //moderate blue
      userdefined : pttColor
    },
    secondary: {
      main: stColor, //red 
      userdefined : sttColor
    },
    background: {
      paper: dark == 'dark' ? grey[800] : bgPaperLight,
      default: dark == 'dark' ? grey[800] : bgPaperLight ,
    },
    action : {
      buttons : btColor,
      buttonsS : btColorS
    }
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    h5: {
      letterSpacing: 0.5,
    },
 
    subtitle1: {
      fontWeight: 500,
      letterSpacing: 0.5,
    },
  }, 

}

let theme = createMuiTheme({
  ...t1,
 
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.type == 'dark' ? grey[900] : theme.palette.background.paper ,
      },
    },
    MuiAppBar: {
        colorTransparent: {
          backgroundColor:  theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.background.paper,
        }
    },
    MuiButton: {
      label: {
   //     textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
 //       backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
 //       backgroundColor: theme.palette.background.drawer,
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    
  },
};
return theme;
}

