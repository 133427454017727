import React from 'react';
import { Component,Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Resizer from 'react-image-file-resizer';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import qrcodeIcon from '@iconify/icons-mdi/qrcode';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageUploader from 'react-images-upload';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { server,cookies,domain_name,local,store_type,getMOS } from '../environ';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import {translator} from  '../parrot-js/parrot';
import InputBase from '@material-ui/core/InputBase';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { LineChart, Line, XAxis, YAxis,Tooltip, CartesianGrid, Legend, ResponsiveContainer,ReferenceDot,ReferenceArea,BarChart,Bar } from 'recharts';
//import numeric from 'numeric'

const useStyles = theme => ({
  root: {
    width: '100%',
  },
   lroot: {
    width: '100%',
    margin : theme.spacing(0, 0, 4, 0)
  },
   formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
   title: {
    flexGrow: 1,
  },
  noitems: {
    minHeight: '80vh',
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'column',
    margin : theme.spacing(0, 4)
  }
});

const colorCBC = "#FCFFFF"
const colorCSC = "#4d79ff"
const colorPBCS = "#39A2DB"
const colorPBC = "#E8F0F2"
const colorIBC = "#EFFAFA"


const object_name = "Portfolio";
const object_table = "tb_portfolio";



const listD = { pid : "stock_id", sid : "" }

function mzp(x){
  if (x == undefined) return ""; 
  var neg = (x < 0) ? -1 : 1; x = neg*x;


  var y = parseFloat(x);
  if (y >= 1000000000000) return (neg == -1 ? "-" : "") + mz(y/1000000000000) + 'T';
  if (y >= 1000000000) return (neg == -1 ? "-" : "") + mz(y/1000000000) + 'B';
  if (y >= 1000000) return (neg == -1 ? "-" : "") + mz(y/1000000) + 'M';
  if (y >= 1000) return (neg == -1 ? "-" : "") + mz(y/1000) + 'K';

  
}

function mz(x){

  var y = parseFloat(x);
  var neg = (y < 0) ? -1 : 1; y = neg*y;
  if (y >= 100) return Math.round(y);
  if (y >= 1 & y<100 ) return y.toFixed(1);
  if (y >= 0.1 & y < 1) return y.toFixed(3);
  if (y >= 0.01 & y < 0.1) return y.toFixed(4);
  if (y >= 0.001 & y < 0.01) return y.toFixed(5);
  if (y >= 0.0001 & y < 0.001) return y.toFixed(6);
  if (y >= 0.00001 & y < 0.0001) return (10000*y).toFixed(3) + 'x10^4';
  if (y >= 0.000001 & y < 0.00001) return (10000*y).toFixed(4) + 'x10^4';
  if (y >= 0.0000001 & y < 0.000001) return (10000*y).toFixed(5) + 'x10^4';


  return y*neg;
}

function upperHalf(data) {
   var l = data.length; var d = [];
   data.forEach( (x,index) => { if (index > l/2) d.push(x.c); })
   return d;
}

function extrapolate(id,coff) {
    var l = id.length; var d = [];
    id.forEach( (x,index) => { var r = (index < l/2) ? x : regF(index,coff); d.push(r); })
    return d;
}

function extrapolate2(data,coff,uHD) { 
    var l = data.length; var d = [];

    data.forEach( (x,index) => { var r = x;
      if (index < l/2) 
        r.c = uHD[index];
      else
        r.c = regF(index,coff);

      d.push(r);
    })
    console.log(d)
    return d;
}

function regF(index,coff) {
        var r = 0;
        coff.forEach( (c,i) => { r = r + c*Math.pow(index,i); });
        return mz(r);
}
/*
function regression (y,order) {

    var xM = [];
    var xT = [];
    var yM = numeric.transpose([y]);

    for (var j=0; j < y.length; j++)
    {
        xT = [];
        for(var i=0; i <= order; i++)
        { xT.push(1*Math.pow(j,i)); }
        xM.push(xT);
    }

    var xMT = numeric.transpose(xM);
    var dot1 = numeric.dot(xMT,xM);
    var dotInv = numeric.inv(dot1);
    var dot2 = numeric.dot(xMT,yM);
    var solution = numeric.dot(dotInv,dot2);
    return solution;
}
*/
function readIDB(key,db,callback) {
 // console.log("read idb " + key, db )
  if (db == null) return callback("db not ready",null)
  var r = db.transaction(["xyz"]).objectStore("xyz").get(key);
  r.onerror = function(event) { console.log("Unable to retrieve data from database! " + key);
    return callback(event,null)
  };
  r.onsuccess = function(event) { // console.log("success" + r.result)
    return (r.result == undefined) ? callback("error",null) : callback(null,r.result.data); 
  };
}

function addIDB(key,data,db) {
  if (db != null){
    var r = db.transaction("xyz", "readwrite").objectStore("xyz").add({key :key,data : data});   
    r.onsuccess = function(event) { };
    r.onerror = function(event) { console.log("unable to add " + key,event); }
  }
}

class X extends Component {

  constructor(props){
      super(props)
      this.state = {
        list : [],
        open : false,
        modify_item_data : {},
        modify_item : false,
        iconTag : null,
        historicalData : [],
        toShowData : [],
        period : "Y",
        max : 0,
        min : 10000000000,
        expand : false,
        cached : false,
        quotedata : {},
        showEarnings : false,
        editBuyDate : false
      }
  }

  componentDidMount = () => { //console.log(this.props.row.stock_id,"cdm")
    this.fetchList();
  }

  componentDidUpdate = (prevProps) => { 

    if (prevProps.row.stock_id != this.props.row.stock_id)
    {  console.log("new stockid cdu")
      this.processChart([],this.state.period);
      this.setState({quotedata : {}, expand : false, showEarnings : false, period : "Y" });
      this.fetchList();
    }
    if (prevProps.period != this.props.period)
    { 
      this.setState({ period : this.props.period })
      this.processChart(this.state.historicalData,this.props.period);
    }
  }



  fetchList = (ind) => { 

    let url = server + '/public/yahoofinance/historical?' + 'symbol=' + this.props.row.stock_id + '&from=' + '2016-05-01' + '&to=' + '2122-01-01'

    var data = null;
    var key = 'h/' + this.props.row.stock_id + '/' + (new Date()).toISOString().split('T')[0];
    var stock_id = this.props.row.stock_id; //console.log("get historicaldata " + key)
    readIDB(key,this.props.db,(err,cached) => {

      if (cached != undefined && cached != null && err == null) { 
     //     console.log("found cached " + key )
          data = cached;
          this.processData(data);
      }
      else
      { //console.log("no cache fetching for " + key)
      fetch(url)
      .then(response => response.json())
      .then(jsondata => { 
      if(jsondata.status == "success") { //console.log("rendering and add " + key)
        data = jsondata.data;
        if (stock_id == this.props.row.stock_id)
          this.processData(data);
        try  { addIDB(key,data,this.props.db) }
        catch (e) { console.log(e,this.props.row.stock_id); }
      }
      })
      .catch(err =>  {this.setState({ linI : false, cirI : false})} )
      }

    })

  //  if (cached != undefined && cached != null)
    
  }

  processData = (data) => {
     
      data.forEach(x => { x.d = x.d.split('T')[0]});
      this.setState({ historicalData : data});
      this.processChart(data,this.state.period);
      this.getLatestQuotes(data);

  }

  getLatestQuotes = (historicaldata) => {

    let url = server + '/public/yahoofinance/quote?' + 'symbol=' + this.props.row.stock_id

    var data = null; var dt = new Date();
    var key = 'q/' + this.props.row.stock_id + '/' + dt.toISOString().split('T')[0] + '/' + Math.trunc((dt.getHours()*60 + dt.getMinutes())/15)
    var stock_id = this.props.row.stock_id;
 //   console.log("get quotes " + key)
   
    readIDB(key,this.props.db,(err,cached) => { 

      if (cached != undefined && cached != null && err == null) { 
    //      console.log("found cached " + key )
          data = cached;
          var a = historicaldata.reverse();
          a.push({d : (new Date()).toISOString().split('T')[0], c : data.r })
          this.setState({quotedata : data})
          this.processChart(a.reverse(),this.state.period);
       //   this.processData(data);
      }
      else
      { //console.log("no cache fetching for " + key)
      fetch(url)
      .then(response => response.json())
      .then(jsondata => { 
      if(jsondata.status == "success") { //console.log("rendering and add " + key)

        data = jsondata.data;
      //  console.log(jsondata.data)

        if (stock_id == this.props.row.stock_id)
        {
            var a = historicaldata.reverse();
            a.push({d : (new Date()).toISOString().split('T')[0], c : data.r })
            this.setState({quotedata : data})
            this.processChart(a.reverse(),this.state.period);
        } 
        try  { addIDB(key,data,this.props.db) }
        catch (e) { console.log(e,this.props.row.stock_id); }

      }
      })
      .catch(err =>  {this.setState({ linI : false, cirI : false})} )
      }
    })

  }


 

  processChart = (data,period) => { //console.log("processChart")
    var limit = 2000;
    if (period == "W") limit = 7;
    if (period == "M") limit = 30;
    if (period == "Y") limit = 365;
    if (period == "5Y") limit = 1825;
    
    var d = []; var max = 0; var min = 10000000000;
    data.forEach( (x,index) => {
      if (index < limit && x.c != null )
      {  
        d.push(x); if (x.c != undefined && max == 0) max = x.c; if (x.c != undefined ) min = x.c;
      }
    }); 
    var e = d.reverse();
    var n = this.props.row.updated_on != undefined ?  (new Date(this.props.row.updated_on)).toISOString().split('T')[0] : null
    e.forEach( (x,index) => { 
      if (  n == x.d )
        this.setState({ dotx : index, doty : x.c, dot : x})
    })

    this.setState({ toShowData : e,
      //toShowData : a,
      max : max, min : min})
  }

  handleClick = (data, index) => {
    console.log(index)
  };

  demoOnClick = (e) => {
    if (this.props.page == "page/portfolio" && this.state.editBuyDate == true)
    {
      var d = e;
      this.setState({ dotx : d.activeTooltipIndex, doty : d.activePayload[0].payload.c, dot : d.activePayload[0].payload })
    }
  }

  onUpdatePortfolioDate = () => {
    this.props.createEditObject('edit',this.props.row.id,{updated_on : ( new Date(this.state.dot.d) ).toISOString().split('T')[0] },false)
  }



  render() {
  const { classes } = this.props;

  var lc = (this.props.lc == undefined) ? 'en' : this.props.lc;
  var p = new translator(lc,'/w/menu');



  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) { //console.log(payload)
      return ( 
        <div className="custom-tooltip"  >
          <p style={{  margin : 0}} >{mz(payload[0].value)}</p>
          <p style={{  margin : 0, opacity : 0.5, marginTop : 2}}>{payload[0].payload['d']}</p>
        </div>
      );
    }
    return null;
  };
  const CustomTooltipBar = ({ active, payload, label }) => {
    if (active && payload && payload.length) { //console.log(payload)
      return ( 
        <div className="custom-tooltip">
            <div style={{ borderRadius : "4px", padding : "2px 10px", backgroundColor : "rgba(255,255,255,0.9)" }} >
            <p style={{ display : "inline", marginRight : "4px"}}>{"Earnings : " + mzp(payload[0].payload['earnings']) }</p>
            <p style={{ display : "inline"}} >{"Revenue : " + mzp(payload[0].payload['revenue']) }</p>
            </div>
        </div>
      );
    }
    return null;
  };

  var hide = this.props.row.hide == 1 && this.props.page == "page/onwatch";

  var at = (this.props.row.tags != null) ? JSON.parse(this.props.row.tags) : [];

 // at = at.filter(x => { return x != null })

  return (
    <Grid item xs={12} sm={4} onClick={e => { if (hide) this.props.createEditObject('edit',this.props.row.id,{ hide : 0},false)} }  >
    
    <Box p={1.5} ml={0.5} mr={0.5} mb={0} mt={2.5} style={{ borderRadius : 8, backgroundColor : colorCBC}}>
    <Grid container justify="space-between" >
    <Grid item>
    <Grid container direction="column" alignItems="flex-start">
    { this.props.row.stock_id }
    </Grid>
    </Grid>
    <div style={ hide ? {display : "none"} : {}}>
    {["W","M","Y","5Y"].map(x => {
      return(
        <div style={{   marginLeft : "10px", display: "inline"}} onClick={e => { this.setState({ period : x }); this.processChart(this.state.historicalData,x); }}>
        <div style={ this.state.period == x  ? { backgroundColor: colorPBCS,  borderRadius : 4, fontSize : "14px",paddingLeft : "8px",paddingRight : "8px", paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
                                            : { backgroundColor: colorPBC,  borderRadius : 4, fontSize : "14px",paddingLeft : "8px",paddingRight : "8px",paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
                    }
        children ={x}/> 
        </div>
      );
    })}
    </div>
    </Grid>
    <Box p={0.3}/>

    {this.props.page=="page/onwatch" && 
    <div>
    { !this.state.addtag  ?
    <Grid container alignItems="center">
    { at.filter(x => { return x != null }).map(x => { return ( <div style={{ fontSize : 13, marginTop : "4px", marginRight : "8px", padding : "2px 4px", backgroundColor : colorPBC, display : "inline", borderRadius : "4px" }}> {x.toUpperCase()} </div> )}) }
    <div children="+" style={{  marginTop : "4px", padding : "1px 4px", backgroundColor : colorPBC, display : "inline", borderRadius : "4px" }} onClick={e => this.setState({addtag : true})}/> </Grid>
    : 
    <ClickAwayListener touchEvent={false} onClickAway={e => this.setState({addtag : false}) }>  
    <Autocomplete
      multiple
      freeSolo
      openOnFocus
      disableClearable
      id="tags-standard"
      options={this.props.tags}
      defaultValue={at}
      onChange={ (e,x,r) => { console.log(r); if (r != "clear" || r != "close") { this.props.createEditObject('edit',this.props.row.id,{ tags : JSON.stringify(x) },null); this.props.row.tags = JSON.stringify(x); } }}
      onClose={e => {this.setState({addtag : false} )}}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input style={{ width: 200 }} type="text" {...params.inputProps} />
        </div>
      )}
      renderTags={(at) => (
        <div>
        {at.filter(x => { return x != null }).map(x => {
          return (
            <div style={{ fontSize : 13, marginTop : "4px", marginRight : "8px", padding : "2px 4px", backgroundColor : "lightgrey", display : "inline", borderRadius : "4px" }}> {x.toUpperCase()} </div>
          )
        })}
        </div>
      )}
  
      renderInput={(params) => (
        <TextField 
          {...params} autoFocus
          variant="standard"
          InputProps={{ ...params.InputProps,disableUnderline: true }}
          placeholder="Add Tag"
        />
      )}
    />
    </ClickAwayListener>
    }</div>}

    <Box p={0.5}/>
    <div style={ hide ? {display : "none"} : {}}>
    <ResponsiveContainer width="100%" height={100} >
    <LineChart width={320} height={100} data={this.state.toShowData} onClick={this.demoOnClick} >
    <CartesianGrid strokeDasharray="4 4" />
    <YAxis yAxisId={0}  hide width={20} type="number" domain={['auto', 'auto']}  />
    <XAxis xAxisId={0}  hide width={20}/>
    <Tooltip position={{ x: 10, y: 10 }} content={<CustomTooltip />} />
    <Line type="monotone" dataKey="c" strokeWidth={1.5} stroke={colorCSC} dot={false}   />
    { this.props.page == "page/portfolio"  &&
      <ReferenceDot x={this.state.dotx} y={this.state.doty} r={this.state.editBuyDate == false ? 2 : 4} fill="red" stroke="black" strokeWidth={this.state.editBuyDate == false ? 0 : 1} />
    }
    </LineChart>
    </ResponsiveContainer>
    </div>

    { (this.state.max/this.state.min != 0) &&
    <Box pt={0.5} style={ hide ? {display : "none"} : {}}>

    <Grid container justify="space-between">

    <Grid item>
    <Grid container>
    <div title="Change" children={Math.round(100*(this.state.max/this.state.min)-100) + "%"}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.props.displaySM("Change %") } />
    <div title="Last Quote" children={mz(this.state.max) + (this.state.quotedata.cs != undefined ? " " + this.state.quotedata.cs : "") }  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.props.displaySM("Last Quote") } />
    { this.state.quotedata.m != undefined &&
    <div title="Market Cap" children={mzp(this.state.quotedata.m)}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.props.displaySM("Market Cap") } />}
    </Grid>
    </Grid>


    {/*<div>
    <Typography variant="body1" children={ }  style={{display : "inline"}} />
    </div>*/}


    {!this.state.expand ?
    <ExpandMoreIcon onClick={e => {this.setState({ expand : true})  }} /> :
    <ExpandLessIcon onClick={e => {this.setState({ expand : false}) }} />
    }
    </Grid>

    { this.state.expand &&
      <Grid container direction="column" >
      
      <Grid item >
      <Grid container directon="row" style={{ marginTop : "4px"}}>
      { this.state.quotedata.sn != undefined &&
      <div title="Full Name" children={this.state.quotedata.sn}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.props.displaySM("Full Name") } /> }  
      { this.state.quotedata.pe != undefined &&
      <div title="Price to Earnings" children={this.state.quotedata.pe.toFixed(1)}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => {this.props.displaySM("Price to Earnings"); } } /> }  
      { this.state.quotedata.d != undefined &&
      <div title="Dividend" children={(this.state.quotedata.d*100).toFixed(2) + "%"}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.props.displaySM("Dividend") } />}
      { this.state.quotedata.v != undefined &&
      <div title="Volume" children={mzp(this.state.quotedata.v)}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.props.displaySM("Volume") } />}
      </Grid>
      </Grid> 

      { this.props.pickedEx != "CCC" &&
      <Grid item >
      <Grid container directon="row" style={{ marginTop : "4px"}}>
      <div title="View Earnings" children={"View Earnings"}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} onClick={e => this.setState({ showEarnings : !this.state.showEarnings}) } />
      { (this.state.quotedata.fc != this.state.quotedata.c) && this.state.showEarnings && this.state.quotedata.fc != undefined &&
      <div title={"Earnings in " + this.state.quotedata.fc} children={"Earnings in " + this.state.quotedata.fc}  style={{ backgroundColor:colorIBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}} />
      }
      </Grid>
      </Grid>}
      
      
      
      { this.state.quotedata.f != undefined && this.state.showEarnings &&
      <Grid container direction="column" style={{ marginTop : "8px"}}>
      <Grid item>
      
      <ResponsiveContainer width="100%" height={100}>
      <BarChart width={320} height={100} data={this.state.quotedata.f.yearly} >
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="date" />
      <YAxis hide />
      <Tooltip content={<CustomTooltipBar />} />
      <Bar dataKey="earnings" fill={colorPBCS} style={{ opacity : 0.82}} />
      <Bar dataKey="revenue" fill={colorCSC} style={{ opacity : 0.82}} />      
      </BarChart>
      </ResponsiveContainer>

      </Grid>

      <Grid item>
      <ResponsiveContainer width="100%" height={100}>
      <BarChart width={320} height={100} data={this.state.quotedata.f.quarterly} >
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="date" />
      <YAxis hide />
      <Tooltip  content={<CustomTooltipBar />} />
      <Bar dataKey="earnings" fill={colorPBCS} style={{ opacity : 0.82}} />
      <Bar dataKey="revenue" fill={colorCSC} style={{ opacity : 0.82}} />      
      </BarChart>
      </ResponsiveContainer>
      </Grid>

      </Grid>
      }

      <Grid item >
      <Grid container directon="row" style={{ marginTop : "16px"}}>
      <div children="Remove" style={{ backgroundColor:colorPBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}}  onClick={e => this.props.createEditObject('delete',this.props.row.id,{},false) } />
      { this.props.page == "page/portfolio" &&
      <React.Fragment>
      { !this.state.editBuyDate  ?
      <div children="Edit Date" style={{ backgroundColor:colorPBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}}  onClick={e => this.setState({ editBuyDate : true }) } />
      :<div children="Save Date" style={{ backgroundColor:colorPBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 16px 4px 0px"}}  onClick={e => { this.onUpdatePortfolioDate(); this.setState({ editBuyDate : false })} } />
      }
      </React.Fragment>
      }

      { this.props.page == "page/onwatch" && false &&  
        <div children="Hide" style={{ backgroundColor:colorPBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 8px"}}  onClick={e => this.props.createEditObject('edit',this.props.row.id,{ hide : 1},false) } />}
     
      { false && <div> 
      { this.props.page != "page/onwatch" && 
        <div children="To Sell" style={{ backgroundColor:colorPBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 8px"}}  onClick={e => this.props.createEditObjectTodo('create',null,{user_id : this.props.row.user_id , stock_id : this.props.row.stock_id, exchange : this.props.row.exchange , action : "SELL"},false) } />}
      <div children="To Buy"  style={{ backgroundColor:colorPBC, borderRadius : 4, fontSize : "14px", padding : "4px 8px",  display: "inline", margin : "4px 8px"}}  onClick={e => {} } />
      </div>}

      </Grid>
      </Grid>

      </Grid>
       }

    </Box>}

    </Box>
    </Grid> 

  );
  }
}

const StockCellW = withStyles(useStyles)(X);
export default StockCellW



