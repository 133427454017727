import React from 'react';
import { Component,Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Resizer from 'react-image-file-resizer';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import qrcodeIcon from '@iconify/icons-mdi/qrcode';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';

import ImageUploader from 'react-images-upload';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { server,cookies,domain_name,local,store_type,getMOS } from '../environ';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import {translator} from  '../parrot-js/parrot';
import InputBase from '@material-ui/core/InputBase';

import Tooltip from '@material-ui/core/Tooltip';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import StockCellW from './StockCell'

import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
var dateFormat = require("dateformat");


const useStyles = theme => ({
  root: {
    width: '100%',
  },
   lroot: {
    width: '100%',
    margin : theme.spacing(0, 0, 4, 0)
  },
   formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
   title: {
    flexGrow: 1,
  },
  noitems: {
    minHeight: '80vh',
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'column',
    margin : theme.spacing(0, 4)
  }
});

const object_name = "Todos";
const object_table = "tb_todos";

const listD = { pid : "stock_id", sid : "" }

class X extends Component {

  constructor(props){
      super(props)
      this.state = {
        list : [],
        open : false,
        modify_item_data : {},
        modify_item : false,
        iconTag : null,
        data : [],
        textbox : "",
        addItem : false,
        period : "Y",
        exchanges : [],
        toFilterExchanges : [],
        linI : false
      }
  }

  componentDidMount = () => {
    this.fetchList('linear',null);
  }

  componentDidUpdate = (prevProps) => {
  }

  fetchList = (ind) => {
    if (ind == 'circular') this.setState({cirI : true}); else this.setState({linI : !(ind == false) })
      
    let url = server + '/user/object/listall?access_token=' + cookies.get('/user/access_token') + '&lc=' + this.props.lc;
        url = url + '&object=' + object_table
    fetch(url)
    .then(response => response.json())
    .then(jsondata => { 
    if(jsondata.status == "success") {
        jsondata.data.forEach(x => { if (x.exchange=="Industry") x.exchange = "NSE" })
        this.setState({ list : jsondata.data.reverse(), linI : false, cirI : false});
        var exchanges = [];
        jsondata.data.forEach(x => { if (!exchanges.includes(x.exchange)) exchanges.push(x.exchange); })
        this.setState({exchanges : exchanges});
    }
    })
    .catch(err =>  {this.setState({ linI : false, cirI : false})} )
  }

  createEditObject = (opr,object_id,object_data,update) => { 

    var data = { object_data : object_data, object_type : object_table, object_id : object_id, opr : opr};
    data.access_token = cookies.get('/user/access_token');
    data.lc           = this.props.lc;
    
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };
    let url = server + '/user/object/crud'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success") { 
        this.fetchList(update == false ? false : true); 
      }
      else this.props.displaySM("Some Error Occured") 
    });
  }

 
  scrollToBottom = () => {
    const { messageList } = this.refs;
    messageList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  filterExchanges = (x) => {
    var f = [];
    this.state.toFilterExchanges.forEach(y => {if (x != y) f.push(y);} )
    if (!this.state.toFilterExchanges.includes(x)) f.push(x);
    this.setState({toFilterExchanges : f})
  }


  render() {
  const { classes } = this.props;

  var lc = (this.props.lc == undefined) ? 'en' : this.props.lc;
  var p = new translator(lc,'/w/menu');
  return (
    <div>
      
    <AppBar position="relative" elevation={1} color="white">
    <Toolbar>
    <Grid container justify="space-between" alignItems="center">
    
    <Grid item xs={5}>
    {this.state.exchanges.map(x => {
      return(
        <div style={{ marginLeft : "4px",marginRight : "4px", display: "inline", }} onClick={e => this.filterExchanges(x) }>
        <div style={ !this.state.toFilterExchanges.includes(x) ? { border: "1px solid black",   borderRadius : 4, fontSize : "14px",paddingLeft : "3px",paddingRight : "3px", paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
                                            : {   borderRadius : 4, fontSize : "14px",paddingLeft : "3px",paddingRight : "3px",paddingTop : "4px",paddingBottom : "4px",  display: "inline"}
        } children={x} />
        </div>
      );
    })}
    </Grid>

    </Grid>
    </Toolbar>
    </AppBar>
    
    { this.state.linI ? <LinearProgress color="primary" /> : 
    <div>
    <List>
     { this.state.list
      .filter(x => { return !this.state.toFilterExchanges.includes(x.exchange)  } )
      .map( row => {
        return(  

          <ListItem button disabled={row.done == 1}>
          <ListItemText primary={row.stock_id} secondary={row.action + ' ' + ( dateFormat(new Date(row.created_on),"ddd dS mmm  yyyy HH:MM") ) } />
          <ListItemSecondaryAction>
          <Checkbox disabled={row.done == 1} checked={row.done == 1} onClick={e => { this.createEditObject("edit",row.id,{ done : row.done == 1 ? 0 : 1},false); } } />
          </ListItemSecondaryAction>
          </ListItem>
        )
      })
     }
    <Box p={6}  />
    <Box p={1} ref="messageList"/>
   </List>
   { this.state.list.length == 0 && 
    <Box p={6} >
    <Typography className={classes.noitems} align = "center" variant="subtitle1" color="textSecondary"   children={"No todos yet"} />
    </Box>}

   </div>
    }

   

   </div>

  );
  }
}

const TodoW = withStyles(useStyles)(X);
export default TodoW



