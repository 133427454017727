import React from 'react';
import { Component,Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Resizer from 'react-image-file-resizer';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import qrcodeIcon from '@iconify/icons-mdi/qrcode';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';

import ImageUploader from 'react-images-upload';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { server,cookies,domain_name,local,store_type,getMOS } from '../environ';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import {translator} from  '../parrot-js/parrot';
import InputBase from '@material-ui/core/InputBase';

import Tooltip from '@material-ui/core/Tooltip';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import StockCellW from './StockCell'

import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
var dateFormat = require("dateformat");


const useStyles = theme => ({
  root: {
    width: '100%',
  },
   lroot: {
    width: '100%',
    margin : theme.spacing(0, 0, 4, 0)
  },
   formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
   title: {
    flexGrow: 1,
  },
  noitems: {
    minHeight: '80vh',
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'column',
    margin : theme.spacing(0, 4)
  }
});

const typesList = [
{l1 : "Momentum",l2 : "NSDQ",l3 : [4,5], l4 : 256 },
{l1 : "Momentum",l2 : "NSE",l3 : [2], l4 : 256 },
{l1 : "Momentum", l2 : "Crypto",l3 : [1], l4 : 256 },
{l1 : "Momentum",l2 : "NSDQ",l3 : [4,5], l4 : 512 },
{l1 : "Momentum",l2 : "NSE",l3 : [2], l4 : 512 },
{l1 : "Momentum", l2 : "Crypto",l3 : [1], l4 : 512 },
{l1 : "Warren Buffet", l2 : "NSDQ"},
{l1 : "Warren Buffet", l2 : "NSDQ"} ]


class X extends Component {

  constructor(props){
      super(props)
      this.state = {
        subpage : "types",
        stocksdata : []
      }
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps) => {
  }

  fetchStocks = (x) => {


    let url = "https://api-z-back.webberapps.com" + '/reco/monoincfunc'
  
    fetch(url)
      .then(response => response.json())
      .then(jsondata => { 
        console.log(jsondata);
      
          var d = jsondata.data;
          var d1 = d[x.l4]
          var l = [];
          
          [5,4,3,2,1].forEach(b => {
            x.l3.forEach( z => {
             var d2 = d1[z];
             d2["m" + b].forEach(f => {
                l.push(f);
             }) 
            })
          })

          this.setState({stocksdata : l})
        
      })
      .catch( err =>  { } )
  }


 

  render() {
  const { classes } = this.props;


  return (
    <div>
      
    <AppBar position="relative" elevation={1} color="white">
    <Toolbar>
    { this.state.subpage == "stocks" && <ArrowBackIcon onClick={e => {this.setState({subpage : "types"})}}/> }
    </Toolbar>
    </AppBar>
    
    { this.state.subpage == "types" && 
    <div>
    <List>
    {
      typesList.map(x => {
         return (
         <ListItem button onClick={e => { this.setState({ subpage : "stocks"  }); this.fetchStocks(x); }  }>
         <ListItemText primary={x.l1} secondary={x.l2 + " " + x.l4} />  
         </ListItem>
         );

      })

    }
   </List>
   </div>}
   {this.state.subpage == "stocks" && 
    <div>
    {
      <List>
      { this.state.stocksdata.map(x => {
        return (
        <ListItem button >
        <ListItemText primary={x.name + " " + x.stock_name} secondary={x.Gain0[0][0] + " " + "(" + x.monotonic_order + "/" + x.num_days + "/" + x.ex_id + ") " } />  
        </ListItem>
        )
      })

      }
     
      </List>
    }
    </div>
    }
   </div>

  );
  }
}

const FindAssets = withStyles(useStyles)(X);
export default FindAssets



